export const companyDataSelector = state => ({
  isCompanyFetched: state.messagesData.isCompanyFetched,
  isCompanyLoading: state.messagesData.isCompanyLoading,
  companyData: state.messagesData.companyData
});

export const messagesDataSelector = state => ({
  isMessagesFetched: state.messagesData.isMessagesFetched,
  isMessagesLoading: state.messagesData.isMessagesLoading,
  messagesData: state.messagesData.pendingMessages,
  pendingMessagesMeta: state.messagesData.pendingMessagesMeta,
  reviewLoader: state.messagesData.loaders.review
});

export const selectedCompanyIdSelector = state => state.messagesData.selectedCompanyId;