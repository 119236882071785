import { ADD_ALERT, REMOVE_ALERT } from './types';

export const addAlert = (message, type, duration) => async dispatch => {
  dispatch({
    type: ADD_ALERT,
    payload: {
      message,
      ...type && { type },
      ...duration && { duration }
    }
  });
};

export const removeAlert = id => async dispatch => {
  dispatch({
    type: REMOVE_ALERT,
    payload: {
      alertId: id
    }
  });
};