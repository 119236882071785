export const surveysDataSelector = state => ({
  isSurveysFetched: state.surveysData.isSurveysFetched,
  isSurveysLoading: state.surveysData.isSurveysLoading,
  surveysData: state.surveysData.surveysData
});

export const surveyDataSelector = state => ({
  isSurveyFetched: state.surveysData.isSurveyFetched,
  isSurveyLoading: state.surveysData.isSurveyLoading,
  surveyData: state.surveysData.surveyData
});

export const selectedSurveyIdSelector = state => state.surveysData.selectedSurveyId;