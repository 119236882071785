import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/loader';
import DataTable from 'components/data-table';
import { Tabs, Tab } from 'react-bootstrap';
import { updatesDataSelector } from 'store/updates/selectors';
import { callUpdates, callSeenUpdate } from 'store/updates/actions';
import { useDidUpdate } from '../../hooks';

const Updates = () => {
  const dispatch = useDispatch();

  const defaultQueries = {
    limit: 10,
    page: 1,
    s: ''
  };

  const [companiesQueries, setCompaniesQueries] = useState(defaultQueries);

  const [tab, setTab] = useState('companies');

  const {
    isUpdatesFetched,
    isCompaniesLoading,
    updatesData
  } = useSelector(updatesDataSelector, isEqual);

  function loadUpdates() {
    !isCompaniesLoading && dispatch(callUpdates(companiesQueries));
  }

  const searchHandler = handler => searchText => {
    handler(prevState => ({
      ...prevState,
      page: 1,
      s: searchText
    }));
  };

  useEffect(() => {
    !isUpdatesFetched && loadUpdates();

    return () => {
      updatesData?.rows?.length > 0 && dispatch(callSeenUpdate());
    };
  }, []);

  useDidUpdate(() => {
    loadUpdates();
  }, [companiesQueries]);

  return (
    <div className="content">
      <Tabs
        className="table-data-nav"
        activeKey={tab}
        onSelect={k => setTab(k)}
      >
        <Tab eventKey="companies" title="Companies">
          {!isUpdatesFetched ? (
            <Loader/>
          ) : (
            <DataTable
              dataType="updates"
              searchIt={searchHandler(setCompaniesQueries)}
              dataObj={updatesData}
              isLoading={isCompaniesLoading}
            />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default Updates;