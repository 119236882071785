import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { GrDocumentText } from 'react-icons/gr';

import Loader from 'components/loader';
import DataTable from 'components/data-table';
import { SurveyModal } from './surveyModal';

import { useDidUpdate } from '../../hooks';
import { fetchSurveys, setSurvey } from '../../store/surveys/actions';
import { headers, defaultQueries } from './constants';
import { selectedSurveyIdSelector, surveysDataSelector } from '../../store/surveys/selectors';

const tableHeader = () => headers.map(({ value, className }) => <th key={value} className={className}>{value}</th>);

const Surveys = () => {
  const dispatch = useDispatch();
  const selectedSurveyId = useSelector(selectedSurveyIdSelector, shallowEqual);

  const {
    isSurveysFetched,
    isSurveysLoading,
    surveysData
  } = useSelector(surveysDataSelector, isEqual);

  const [surveyQuery, setSurveyQuery] = useState(defaultQueries);

  const selectSurvey = e => {
    const { id } = e.currentTarget.dataset;
    dispatch(setSurvey(+id));
  };

  function loadSurveys() {
    !isSurveysLoading && dispatch(fetchSurveys(surveyQuery));
  }

  const paginationHandler = handler => page => {
    handler(prevState => ({
      ...prevState,
      page
    }));
  };

  useEffect(() => {
    !isSurveysFetched && loadSurveys();
  }, []);

  useDidUpdate(loadSurveys, [surveyQuery]);

  const tableBody = useCallback(() => {
    if (!surveysData.surveys?.length) {
      return (
        <tr>
          <td colSpan="7" className="table-no-information text-center p-4 text-muted">
            No results found
          </td>
        </tr>
      );
    }

    return surveysData?.surveys?.map(survey => (
      <tr
        className={selectedSurveyId === survey.id ? 'selected' : 'selectable'}
        key={survey.id}
        data-id={survey.id}
      >
        <td>{survey.email}</td>
        <td>{survey.firstName} {survey.lastName}</td>
        <td>{survey.phone}</td>
        <td>{moment(survey.createdAt).format('MM/DD/YYYY HH:mm:ss')}</td>
        <td className="text-center">
          <GrDocumentText size="18" data-id={survey.id} onClick={selectSurvey} />
        </td>
      </tr>
    ));
  }, [surveysData, selectedSurveyId]);

  return (
    <div className="content">
      {isSurveysLoading && <Loader />}
      {surveysData && (
        <DataTable
          tableHeader={tableHeader}
          tableBodyContent={tableBody}
          dataObj={surveysData}
          paginationHandler={paginationHandler(setSurveyQuery)}
          isLoading={isSurveysLoading}
        />
      )}
      <SurveyModal surveyId={selectSurvey} />
    </div>
  );
};

export default Surveys;