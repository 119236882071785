/* eslint-disable arrow-body-style */
import {
  ACCEPT_SEQUANCE_CALL,
  ACCEPT_SEQUANCE_SUCCESS,
  ACCEPT_WORKFLOW_CALL,
  ACCEPT_WORKFLOW_SUCCESS,
  DECLINE_SEQUANCE_CALL,
  DECLINE_SEQUANCE_SUCCESS,
  DECLINE_WORKFLOW_CALL,
  DECLINE_WORKFLOW_SUCCESS,
  PENDING_SEQUENCES_SUCCESS,
  PENDING_WORKFLOWS_SUCCESS
} from './types';

const initialState = {
  pendingWorkflows: new Map(),
  pendingWorkflowsMeta: {},

  pendingSequences: new Map(),
  pendingSequencesMeta: {},
  loaders: {
    review: false
  }
};

const campaignsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PENDING_SEQUENCES_SUCCESS:
      return {
        ...state,
        pendingSequences: payload.sequences,
        pendingSequencesMeta: payload.meta
      };
    case PENDING_WORKFLOWS_SUCCESS:
      return {
        ...state,
        pendingWorkflows: payload.workflows,
        pendingWorkflowsMeta: payload.meta
      };
      case ACCEPT_WORKFLOW_CALL:
      case ACCEPT_SEQUANCE_CALL: return {
        ...state,
        loaders: {
          ...state.loaders,
          review: true
        }
      };
      case ACCEPT_WORKFLOW_SUCCESS:
      case DECLINE_WORKFLOW_SUCCESS: return {
        ...state,
        pendingWorkflows: payload,
        loaders: {
          ...state.loaders,
          review: false
        }
      };
      case ACCEPT_SEQUANCE_SUCCESS:
      case DECLINE_SEQUANCE_SUCCESS: return {
        ...state,
        pendingSequences: payload,
        loaders: {
          ...state.loaders,
          review: false
        }
      };
      case DECLINE_WORKFLOW_CALL:
      case DECLINE_SEQUANCE_CALL: return {
        ...state,
        loaders: {
          ...state.loaders,
          review: true
        }
      };
    default:
      break;
  }
  return state;
};

export default campaignsReducer;