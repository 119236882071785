import axios from 'axios';
import { BASE_URL } from '../../../constants';

const axiosInstance = token => axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
  headers: {
    ...token && { Authorization: `Bearer ${token}` },
    'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  }
});

export default axiosInstance;