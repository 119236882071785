import { Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { WarningModal } from 'components';

import {
  getPendingWorkflows,
  getPendingSequences,
  acceptPendingSequences,
  declinePendingSequances,
  acceptPendingWorkflows,
  declinePendingWorkflows
} from 'store/campaigns/actions';
import { campaignsSelector } from 'store/campaigns/selectors';
import DataTable from 'components/data-table';
import Loader from 'components/loader';
import CollapsableRow from './collapsable-row';

const CampaignTabTypes = {
  WORKFLOW: 'workflow',
  SEQUENCE: 'sequence'
};

const headers = [
  {
    className: 'col-1 pl-5',
    value: 'Campaign'
  },
  {
    className: 'col-2',
    value: 'Audience'
  },
  {
    className: 'col-2',
    value: 'Messages'
  },
  {
    className: 'col-1',
    value: 'Date'
  },
  {
    className: 'col-1',
    value: 'Accept / Decline'
  }
];

const defaultQueries = { limit: 10, page: 1 };

const tableHeader = () => headers.map(({ value, className }) => <th key={value} className={className}>{value}</th>);

export const Campaigns = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const {
    workflows,
    workflowsMeta,
    sequences,
    sequencesMeta,
    reviewLoader
  } = useSelector(campaignsSelector, shallowEqual);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tab, setTab] = useState(CampaignTabTypes.WORKFLOW);
  const [loading, setLoading] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [campaignQueries, setCampaignQueries] = useState(defaultQueries);

  const handleSelect = key => {
    if (loading) {
      return;
    }
    setTab(key);
  };

  const fetchData = async () => {
    setLoading(true);
    const actionPromise = tab === CampaignTabTypes.WORKFLOW ? getPendingWorkflows : getPendingSequences;
    await dispatch(actionPromise(companyId, campaignQueries));
    setLoading(false);
  };

  const handleAcceptSequanceClick = e => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    setModalProps({
      warningText: 'Are you sure to accept the sequance?',
      acceptText: 'Accept',
      onAccept: async () => {
        await dispatch(acceptPendingSequences(companyId, [id]));
        setIsModalVisible(false);
        await fetchData();
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  const handleDeclineSequanceClick = e => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    setModalProps({
      warningText: 'Are you sure to decline the sequance?',
      acceptText: 'Decline',
      onAccept: async () => {
        await dispatch(declinePendingSequances(companyId, [id]));
        setIsModalVisible(false);
        await fetchData();
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  const handleAcceptWorkflowClick = e => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    setModalProps({
      warningText: 'Are you sure to accept the workflow?',
      acceptText: 'Accept',
      onAccept: async () => {
        await dispatch(acceptPendingWorkflows(companyId, [id]));
        setIsModalVisible(false);
        await fetchData();
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  const handleDeclineWorkflowClick = e => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    setModalProps({
      warningText: 'Are you sure to decline the workflow?',
      acceptText: 'Decline',
      onAccept: async () => {
        await dispatch(declinePendingWorkflows(companyId, [id]));
        setIsModalVisible(false);
        await fetchData();
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  useEffect(() => {
    setCampaignQueries({ ...defaultQueries });
  }, [tab]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [campaignQueries]);

  useEffect(() => {
    const data = tab === CampaignTabTypes.WORKFLOW ? workflows : sequences;
    const { page, totalPages } = tab === CampaignTabTypes.WORKFLOW
    ? workflowsMeta
    : sequencesMeta;
    const isEmptyTable = data.size === 0 && page >= totalPages;
    if (!isEmptyTable || totalPages === 0) {
      return;
    }
    setCampaignQueries({
      ...defaultQueries,
      page: totalPages
    });
  }, [workflows, sequences, workflowsMeta, sequencesMeta]);

  const workflowsTableBodyContent = useCallback(() => (!workflows.size
      ? <tr><td colSpan="5" className="table-no-information text-center p-4 text-muted">No results found</td></tr>
      : (
        [...workflows.values()].map(workflow => (
          <CollapsableRow
            key={workflow.id}
            cmpItem={workflow}
            acceptAction={handleAcceptWorkflowClick}
            declineAction={handleDeclineWorkflowClick}
          />
        )))), [workflows]);

  const sequencesTableBodyContent = useCallback(() => (!sequences.size
      ? <tr><td colSpan="5" className="table-no-information text-center p-4 text-muted">No results found</td></tr>
      : (
        [...sequences.values()].map(sequence => (
          <CollapsableRow
              key={sequence.id}
              cmpItem={sequence}
              acceptAction={handleAcceptSequanceClick}
              declineAction={handleDeclineSequanceClick}
          />
        )))), [sequences]);

  const paginationHandler = handler => page => {
    handler(prevState => ({
      ...prevState,
      page
    }));
  };

  return (
    <div className="content">
      <Tabs activeKey={tab} className="table-data-nav" onSelect={handleSelect}>
        <Tab eventKey="workflow" title="Workflows">
          {loading && <Loader />}
          <DataTable
            dataObj={workflowsMeta} paginationHandler={paginationHandler(setCampaignQueries)} tableHeader={tableHeader}
            tableBodyContent={workflowsTableBodyContent}
          />
        </Tab>
        <Tab eventKey="sequence" title="Sequences">
          {loading && <Loader />}
          <DataTable
            dataObj={sequencesMeta} paginationHandler={paginationHandler(setCampaignQueries)} tableHeader={tableHeader}
            tableBodyContent={sequencesTableBodyContent}
          />
        </Tab>
      </Tabs>
      <WarningModal isLoading={reviewLoader} show={isModalVisible} {...modalProps} />
    </div>
  );
};