import moment from 'moment';
import { isEqual } from 'lodash';
import Loader from 'components/loader';
import { useParams } from 'react-router-dom';
import DataTable from 'components/data-table';
import { BsCheckLg, BsXCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';

import { WarningModal } from 'components';

import { iconAccept, iconDecline } from './messages.module.scss';

import { messagesDataSelector, companyDataSelector } from '../../store/messages/selectors';
import {
  callCompany,
  callMessages,
  acceptPendingMessage,
  declinePendingMessage
} from '../../store/messages/actions';

const headers = [
  {
    className: 'col-1',
    value: 'Contact'
  },
  {
    className: 'col-1',
    value: 'Contact phone'
  },
  {
    className: 'col-3',
    value: 'Message'
  },
  {
    className: 'col-1',
    value: 'Date'
  },
  {
    className: 'col-1',
    value: 'Accept / Decline'
  }
];
const tableHeader = () => headers.map(({ value, className }) => <th key={value} className={className}>{value}</th>);

const defaultQueries = { limit: 10, page: 1 };

const Messages = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [messagesQueries, setMessagesQueries] = useState(defaultQueries);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const {
    isCompanyFetched,
    isCompanyLoading,
    companyData
  } = useSelector(companyDataSelector, isEqual);

  const {
    isMessagesFetched,
    isMessagesLoading,
    messagesData,
    pendingMessagesMeta,
    reviewLoader
  } = useSelector(messagesDataSelector, isEqual);


  function loadMessages() {
    !isMessagesLoading && dispatch(callMessages(companyId, messagesQueries));
  }

  function loadCompany() {
    !isCompanyLoading && dispatch(callCompany(companyId));
  }

  const paginationHandler = handler => page => {
    handler(prevState => ({
      ...prevState,
      page
    }));
  };

  const handleAcceptClick = e => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    setModalProps({
      warningText: 'This message will send immediately, are you sure to accept it?',
      acceptText: 'Accept',
      onAccept: async () => {
        await dispatch(acceptPendingMessage(id, companyId));
        setIsModalVisible(false);
        loadMessages();
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  const handleDeclineClick = e => {
    const id = parseInt(e.currentTarget.dataset.id, 10);
    setModalProps({
      warningText: 'Are you sure to decline the message?',
      acceptText: 'Decline',
      onAccept: async () => {
        await dispatch(declinePendingMessage(id, companyId));
        setIsModalVisible(false);
        loadMessages();
      },
      onCancel: () => setIsModalVisible(false)
    });
    setIsModalVisible(true);
  };

  const tableBodyContent = useCallback(() => (!messagesData.size
    ? (
      <tr>
        <td colSpan="5" className="table-no-information text-center p-4 text-muted">No results found</td>
      </tr>
    )
    : (
      [...messagesData.values()].map(({ id, contact, msg, createdAt }) => (
        <tr key={id}>
          <td className="col-2">
            {contact.firstName} {contact.lastName}
          </td>
          <td className="col-2">{contact.phone}</td>
          <td className="col-4">{msg}</td>
          <td className="col-1">{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
          <td className="col-1 pl-3">
            <BsCheckLg size="14" className={iconAccept} onClick={handleAcceptClick} data-id={id} />
            <BsXCircle size="14" className={iconDecline} onClick={handleDeclineClick} data-id={id} />
          </td>
        </tr>
      ))
    )), [messagesData]);

  useEffect(() => {
    loadCompany(companyId);
  }, [companyId]);

  useEffect(() => {
    loadMessages();
  }, [messagesQueries]);

  useEffect(() => {
    const { page, totalPages } = pendingMessagesMeta;
    const isEmptyTable = messagesData.size === 0 && page >= totalPages;
    if (!isEmptyTable || totalPages === 0) {
      return;
    }
    setMessagesQueries({
      ...defaultQueries,
      page: totalPages
    });
  }, [pendingMessagesMeta, messagesData]);

  return (
    <>
      <div className="content">
        {isCompanyFetched === true && (
          <>
            <h3>Pending Messages</h3>
            <div><strong>Company Name:&nbsp;</strong>{companyData.name}</div>
            <div><strong>Phone:&nbsp;</strong>{companyData.phone}</div>
          </>
        )}
        {isMessagesLoading === true && <Loader />}
        {isMessagesFetched === true && (
          <DataTable
            dataType="messages"
            headers={headers}
            dataObj={pendingMessagesMeta}
            tableHeader={tableHeader}
            tableBodyContent={tableBodyContent}
            paginationHandler={paginationHandler(setMessagesQueries)}
            isLoading={isCompanyLoading}
          />
        )}
      </div>
      <WarningModal isLoading={reviewLoader} show={isModalVisible} {...modalProps} />
    </>
  );
};

export default Messages;