import { apiFetch } from 'utils';
import { addAlert } from '../app/actions';
import {
  COMPANIES_CALL,
  COMPANIES_RESPONSE,
  USERS_CALL,
  USERS_RESPONSE,
  CONTACTS_CALL,
  CONTACTS_RESPONSE,
  SELECT_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_CALL,
  ENTERPRISE_USER_CREATE,
  ENTERPRISE_USER_CREATE_SUCCESS,
  ENTERPRISE_USER_CREATE_FAIL,
  DELETE_COMPANY_FAILURE,
  ACCEPT_COMPANY_CALL,
  ACCEPT_COMPANY_SUCCESS,
  ACCEPT_COMPANY_FAILURE
} from './types';

export const setCompany = companyId => async dispatch => {
  dispatch({
    type: SELECT_COMPANY,
    payload: {
      companyId
    }
  });
};

export const callCompanies = queryParams => async dispatch => {
  dispatch({ type: COMPANIES_CALL });

  let data;
  try {
    data = await apiFetch('get', '/admin/companies', {
      params: queryParams
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
  }

  dispatch({
    type: COMPANIES_RESPONSE,
    ...data && {
      payload: {
        companiesData: data
      }
    }
  });
};

export const callUsers = (companyId, queryParams) => async dispatch => {
  dispatch({ type: USERS_CALL });

  let data;
  try {
    data = await apiFetch('get', `/admin/company/users/${companyId}`, {
      params: queryParams
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
  }

  dispatch({
    type: USERS_RESPONSE,
    ...data && {
      payload: {
        usersData: data
      }
    }
  });
};

export const callContacts = (companyId, queryParams) => async dispatch => {
  dispatch({ type: CONTACTS_CALL });

  let data;
  try {
    data = await apiFetch('get', `/admin/company/contacts/${companyId}`, {
      params: queryParams
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
  }

  dispatch({
    type: CONTACTS_RESPONSE,
    ...data && {
      payload: {
        contactsData: data
      }
    }
  });
};

export const deleteCompany = (companyId, companiesQueries) => async dispatch => {
  dispatch({ type: DELETE_COMPANY_CALL });

  let data;
  try {
    data = await apiFetch('delete', `/admin/company/${companyId}`);

    if (data.status) {
      dispatch({ type: DELETE_COMPANY_SUCCESS });
      dispatch(addAlert('Company has been deleted successfully', 'success'));
      dispatch(callCompanies(companiesQueries));
    }
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
    dispatch({ type: DELETE_COMPANY_FAILURE });
  }
};

export const createEnterpriseCompany = companyData => async dispatch => {
  dispatch({ type: ENTERPRISE_USER_CREATE });
  try {
    await apiFetch('post', '/admin/enterprise', companyData);
    dispatch({ type: ENTERPRISE_USER_CREATE_SUCCESS });
    dispatch(addAlert('Enterprise user created successfully', 'success'));
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
    dispatch({ type: ENTERPRISE_USER_CREATE_FAIL });
  }
};

export const acceptCompany = (companyId, companiesQueries) => async dispatch => {
  dispatch({ type: ACCEPT_COMPANY_CALL });
  try {
    await apiFetch('patch', `/admin/company/${companyId}`);
    dispatch({ type: ACCEPT_COMPANY_SUCCESS });
    dispatch(addAlert('Company has been accepted successfully', 'success'));
    dispatch(callCompanies(companiesQueries));
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
    dispatch({ type: ACCEPT_COMPANY_FAILURE });
  }
};