import { combineReducers } from 'redux';
import appReducer from 'store/app/reducer';
import userReducer from 'store/user/reducer';
import statisticsReducer from 'store/statistics/reducer';
import companiesReducer from 'store/companies/reducer';
import updatesReducer from 'store/updates/reducer';
import messagesReducer from 'store/messages/reducer';
import campaignsReducer from 'store/campaigns/reducer';
import surveysReducer from 'store/surveys/reducer';
// import authReducer from './auth/reducer'

const rootReducer = combineReducers({
  // auth: authReducer,
  appData: appReducer,
  campaigns: campaignsReducer,
  companiesData: companiesReducer,
  messagesData: messagesReducer,
  statisticsData: statisticsReducer,
  surveysData: surveysReducer,
  updatesData: updatesReducer,
  userData: userReducer
});

export default rootReducer;