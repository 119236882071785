export const COMPANY_CALL = 'COMPANY_CALL';
export const COMPANY_RESPONSE_SUCCESS = 'COMPANY_RESPONSE_SUCCESS';
export const COMPANY_RESPONSE_FAILURE = 'COMPANY_RESPONSE_FAILURE';

export const MESSAGES_CALL = 'MESSAGES_CALL';
export const MESSAGES_RESPONSE_SUCCESS = 'MESSAGES_RESPONSE_SUCCESS';
export const MESSAGES_RESPONSE_FAILURE = 'MESSAGES_RESPONSE_FAILURE';

export const ACCEPT_MESSAGE_CALL = 'ACCEPT_MESSAGE_CALL';
export const ACCEPT_MESSAGE_SUCCESS = 'ACCEPT_MESSAGE_SUCCESS';

export const DECLINE_MESSAGE_CALL = 'DECLINE_MESSAGE_CALL';
export const DECLINE_MESSAGE_SUCCESS = 'DECLINE_MESSAGE_SUCCESS';