import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { dataForChart } from 'utils';

import './chart-data.scss';

const ChartData = ({
                     title,
                     yAxesLabel,
                     data,
                     valueKey,
                     config
                   }) => {
  const { chartData, maxThreshold } = dataForChart(data, valueKey, config);

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{}],
      yAxes: [{
        afterTickToLabelConversion: scaleInstance => {
          // eslint-disable-next-line no-param-reassign
          scaleInstance.ticks[0] = null;
          // scaleInstance.ticks[scaleInstance.ticks.length - 1] = null
          // eslint-disable-next-line no-param-reassign
          scaleInstance.ticksAsNumbers[0] = null;
          // scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: maxThreshold < 10 ? maxThreshold + 1 : maxThreshold + 10,
          callback(value) {
            if (Math.floor(value) === value) {
              return value;
            }
            return null;
          }
        }
      }]
    }
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem) {
    //       return tooltipItem.yLabel
    //     },
    //   },
    // },
  };

  return (
    <div className="chart-wrapper">
      <h4>{title}</h4>
      <p className="chart-label">{yAxesLabel}</p>
      <div className={`no-data-information ${maxThreshold > 0 ? 'hide' : ''}`}> No {title} data</div>
      <div className="chart-canvas-wrapper" style={{ display: maxThreshold > 0 ? 'block' : 'none' }}>
          <Line options={chartOptions} data={chartData}/>
      </div>
      <p className="chart-label text-right">Date</p>
    </div>
  );
};

ChartData.propTypes = {
  title: PropTypes.string,
  yAxesLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  config: PropTypes.shape({}).isRequired
};

ChartData.defaultProps = {
  title: '',
  yAxesLabel: ''
};

export default ChartData;