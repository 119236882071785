import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Spinner } from 'react-bootstrap';

export const WarningModal = ({
  handleClose,
  warningText,
  acceptText,
  cancelText,
  isLoading,
  onAccept,
  onCancel,
  show
}) => (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Body className="border-0 pt-4"><span>{warningText}</span></Modal.Body>
      <Modal.Footer>
        {onCancel && cancelText && (
          <Button
            disabled={isLoading}
            onClick={onCancel}
            variant="gray"
          >
            {cancelText}
          </Button>
        )}
        <Button
          disabled={isLoading}
          onClick={onAccept}
          variant="orange"
        >
          {isLoading && <Spinner animation="border" variant="light" size="sm" className="mr-2" />}
          {acceptText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

WarningModal.defaultProps = {
  cancelText: 'Cancel',
  acceptText: 'Accept',
  warningText: 'Warning',
  onAccept: null,
  onCancel: null,
  handleClose: null,
  isLoading: false
};

WarningModal.propTypes = {
  warningText: PropTypes.string,
  acceptText: PropTypes.string,
  handleClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  isLoading: PropTypes.bool
};