import {
  STATISTICS_CALL,
  STATISTICS_RESPONSE
} from './types';

const initialState = {
  isLoading: true,
  statistics: {}
};

const statisticsReducer = (state = initialState, {
  type,
  payload
}) => {
  const newState = { ...state };

  switch (type) {
    case STATISTICS_CALL: {
      newState.isLoading = true;
      newState.statistics = {};

      return newState;
    }

    case STATISTICS_RESPONSE: {
      newState.isLoading = false;

      if (payload?.statistics) {
        newState.statistics = { ...payload.statistics };
      }

      return newState;
    }

    default:
      return state;
  }
};

export default statisticsReducer;