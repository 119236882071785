export const headers = [
  {
    className: 'col-2',
    value: 'Email'
  },
  {
    className: 'col-1',
    value: 'Name'
  },
  {
    className: 'col-1',
    value: 'Phone'
  },
  {
    className: 'col-2',
    value: 'Date Created'
  },
  {
    className: 'col-1',
    value: 'Actions'
  }
];

export const defaultQueries = {
  limit: 10,
  page: 1
};