import { apiFetch } from 'utils';
import { addAlert } from '../app/actions';
import { UPDATES_CALL, UPDATES_RESPONSE, ADD_UPDATE, UPDATES_SEEN_CALL } from './types';

export const callUpdates = queryParams => async dispatch => {
  dispatch({ type: UPDATES_CALL });

  let data;
  try {
    data = await apiFetch('get', '/admin/updates', {
      params: queryParams
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
  }

  dispatch({
    type: UPDATES_RESPONSE,
    ...data && {
      payload: {
        updatesData: data
      }
    }
  });
};

export const callSeenUpdate = () => async dispatch => {
  try {
    await apiFetch('put', '/notifications/seen/all');
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
  }

  dispatch({
    type: UPDATES_SEEN_CALL
  });
};

export const addUpdate = data => async dispatch => {
  dispatch({
    type: ADD_UPDATE,
    payload: {
      data
    }
  });
};