export const headers = [
  {
    className: 'col-4',
    value: 'Company Name'
  },
  {
    className: 'col-1',
    value: 'Date Created'
  },
  {
    className: 'col-2',
    value: 'Creator Email'
  },
  {
    className: 'col-2',
    value: 'Full Name'
  },
  {
    className: 'col-1',
    value: 'Users'
  },
  {
    className: 'col-1',
    value: 'Contacts'
  },
  {
    className: 'col-1',
    value: 'Actions'
  }
];

export const tableWrapperStyle = {
  minWidth: '1000px'
};