import { apiFetch } from 'utils';
import { addAlert } from '../app/actions';
import {
  COMPANY_CALL,
  COMPANY_RESPONSE_SUCCESS,
  COMPANY_RESPONSE_FAILURE,
  MESSAGES_RESPONSE_SUCCESS,
  MESSAGES_RESPONSE_FAILURE,
  MESSAGES_CALL,
  ACCEPT_MESSAGE_CALL,
  ACCEPT_MESSAGE_SUCCESS,
  DECLINE_MESSAGE_CALL,
  DECLINE_MESSAGE_SUCCESS
} from './types';

export const callCompany = companyId => async dispatch => {
  dispatch({ type: COMPANY_CALL });

  try {
    const data = await apiFetch('get', `/admin/company/${companyId}`);

    dispatch({
      type: COMPANY_RESPONSE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
    dispatch({ type: COMPANY_RESPONSE_FAILURE });
  }
};

export const callMessages = (companyId, queryParams) => async dispatch => {
  dispatch({ type: MESSAGES_CALL });

  try {

    const { data, page, totalItems } = await apiFetch('get', `/admin/${companyId}/pending-messages`, { params: queryParams });

    const messagesMap = data.reduce((acc, message) => {
      acc.set(message.id, message);
      return acc;
    }, new Map());

    dispatch({
      type: MESSAGES_RESPONSE_SUCCESS,
      payload: {
        messages: messagesMap,
        meta: {
          page,
          totalPages: Math.ceil(totalItems / queryParams.limit)
        }
      }
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
    dispatch({ type: MESSAGES_RESPONSE_FAILURE });
  }
};

export const acceptPendingMessage = (messageId, companyId) => async (dispatch, getState) => {
  dispatch({ type: ACCEPT_MESSAGE_CALL });

  try {
    await apiFetch('patch', `/admin/${companyId}/accept-pending-message/${messageId}`);

    const { messagesData: { pendingMessages } } = getState();
    const mutablePendingMessages = new Map(pendingMessages);
    mutablePendingMessages.delete(messageId);

    dispatch({
      type: ACCEPT_MESSAGE_SUCCESS,
      payload: mutablePendingMessages
    });
  } catch (error) {
    dispatch(addAlert('Unable to accept pending message', 'error'));
  }
};

export const declinePendingMessage = (messageId, companyId) => async (dispatch, getState) => {
  dispatch({ type: DECLINE_MESSAGE_CALL });

  try {
    await apiFetch('patch', `/admin/${companyId}/decline-pending-message/${messageId}`);

    const { messagesData: { pendingMessages } } = getState();
    const mutablePendingMessages = new Map(pendingMessages);
    mutablePendingMessages.delete(messageId);

    dispatch({
      type: DECLINE_MESSAGE_SUCCESS,
      payload: mutablePendingMessages
    });

  } catch (error) {
    dispatch(addAlert('Unable to decline pending message', 'error'));
  }

};