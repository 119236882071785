import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import ChartData from 'components/chart-data';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, ButtonGroup, Button } from 'react-bootstrap';
import { statisticsDataSelector } from '../../store/statistics/selectors';
import { callStatistics } from '../../store/statistics/actions';
import Loader from '../../components/loader';
import { useDidUpdate } from '../../hooks';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [datePeriod, setDatePeriod] = useState('week');

  const periods = {
    week: 7,
    weeks: 14,
    month: 30
  };

  const chartConfigs = {
    companies: {
      label: 'New Companies',
      backgroundColor: '#1f76b410',
      borderColor: '#1F76B4'
    },
    contacts: {
      label: 'New Contacts',
      backgroundColor: '#fba25610',
      borderColor: '#fba256'
    },
    users: {
      label: 'New Users',
      backgroundColor: '#48be8d10',
      borderColor: '#48be8d'
    }
  };

  const {
    isLoading,
    statistics
  } = useSelector(statisticsDataSelector, isEqual);

  const {
    companiesStatistic,
    usersStatistic,
    contactsCount
  } = statistics;

  const chooseDatePeriod = param => {
    setDatePeriod(param);
    // dispatch(requestCompanies(param))
    // dispatch(requestContactsByCompany(param))
    // dispatch(requestContactsByCompany(param))
  };

  function loadStatisticsData() {
    dispatch(callStatistics({
      days: periods[datePeriod]
    }));
  }

  useEffect(() => {
    if (Object.keys(statistics).length === 0) {
      loadStatisticsData();
    }
  }, []);

  useDidUpdate(() => {
    loadStatisticsData();
  }, [datePeriod]);

  return (
    <div className="content">
      <Row>
        <Col className="d-flex justify-content-center">
          <ButtonGroup aria-label="Basic example" className="date-range-group">
            <Button
              variant="secondary"
              className={`${datePeriod === 'week' ? 'active' : ''}`}
              onClick={() => chooseDatePeriod('week')}
              disabled={isLoading}
            >
              Last 7 Days
            </Button>
            <Button
              variant="secondary"
              className={`${datePeriod === 'weeks' ? 'active' : ''}`}
              onClick={() => chooseDatePeriod('weeks')}
              disabled={isLoading}
            >
              Last 14 Days
            </Button>
            <Button
              variant="secondary"
              className={`${datePeriod === 'month' ? 'active' : ''}`}
              onClick={() => chooseDatePeriod('month')}
              disabled={isLoading}
            >
              Last Month
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {companiesStatistic && (
            <Row className="my-5">
              <Col className="d-flex justify-content-center">
                <ChartData
                  title="New Clients"
                  yAxesLabel="Companies Created"
                  data={companiesStatistic}
                  valueKey="companiesCount"
                  config={chartConfigs.companies}
                />
              </Col>
            </Row>
          )}

          {contactsCount && (
            <Row className="mt-5">
              <Col className="d-flex justify-content-center">
                <ChartData
                  title="Contacts"
                  yAxesLabel="Contacts Added per Company"
                  data={contactsCount}
                  valueKey="contactsCount"
                  config={chartConfigs.contacts}
                />
              </Col>
            </Row>
          )}

          {usersStatistic && (
            <Row className="mt-5">
              <Col className="d-flex justify-content-center">
                <ChartData
                  title="Users"
                  yAxesLabel="Number of Users Added per Company"
                  data={usersStatistic}
                  valueKey="usersCount"
                  config={chartConfigs.users}
                />
              </Col>
            </Row>
          )}
        </>
      )}

    </div>
  );
};

export default Dashboard;