import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

import './form-select.scss';

export const FormSelect = ({ options, placeholder, onChange, label, name, isInvalid, error }) => (
  <FormGroup>
    <FormLabel className="form-select--label mb-0" htmlFor={name}>{label}</FormLabel>
    <Select
      className={classNames('form-select', { 'is-invalid': isInvalid })}
      classNamePrefix="form-select"
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      options={options}
      isSearchable={false}
    />
    {isInvalid && <FormControl.Feedback type="invalid" className={classNames('d-block', 'form-select--error')}>{error}</FormControl.Feedback>}
  </FormGroup>
);

FormSelect.defaultProps = {
  isInvalid: false,
  error: ''
};

FormSelect.propTypes = {
  error: PropTypes.string,
  isInvalid: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};