import PropTypes from 'prop-types';
import store from 'store';
import APIError from 'utils/error';
import axiosInstance from './axios';
// import { isEqual } from 'lodash'
// import { useSelector } from 'react-redux'
// import { userDataSelector } from '../../store/user/selectors'

export default async function apiFetch(method, url, data) {
  try {
    // const { activationToken: token } = useSelector(userDataSelector, isEqual)
    const token = store.getState().userData.activationToken;

    // const { data: resData } = await axiosInstance(token)({
    //   method,
    //   url,
    //   data,
    // })

    const { data: resData } = await axiosInstance(token)[method](url, data);
    return resData;
  } catch (resError) {
    throw new APIError(resError);
  }
}

apiFetch.PropTypes = {
  method: PropTypes.string,
  url: PropTypes.string,
  data: PropTypes.shape({})
};