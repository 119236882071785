import { apiFetch } from 'utils';
import { addAlert } from '../app/actions';

import {
  PENDING_WORKFLOWS_SUCCESS,
  PENDING_SEQUENCES_SUCCESS,
  ACCEPT_SEQUANCE_CALL,
  ACCEPT_SEQUANCE_SUCCESS,
  DECLINE_SEQUANCE_CALL,
  DECLINE_SEQUANCE_SUCCESS,
  ACCEPT_WORKFLOW_SUCCESS,
  ACCEPT_WORKFLOW_CALL,
  DECLINE_WORKFLOW_CALL,
  DECLINE_WORKFLOW_SUCCESS
} from './types';

export const getPendingWorkflows = (companyId, params) => async dispatch => {
  try {
    const { data, page, totalItems } = await apiFetch('get', `/admin/${companyId}/pending-workflows`, { params });

    const workflowsMap = data.reduce((acc, workflow) => {
      acc.set(workflow.id, workflow);
      return acc;
    }, new Map());

    dispatch({
      type: PENDING_WORKFLOWS_SUCCESS,
      payload: {
        workflows: workflowsMap,
        meta: {
          page,
          totalPages: Math.ceil(totalItems / params.limit)
        }
      }
    });
  } catch (error) {
    dispatch(addAlert('Unable to get pending workflows', 'error'));
  }
};

export const getPendingSequences = (companyId, params) => async dispatch => {
  try {
    const { data, page, totalItems } = await apiFetch('get', `/admin/${companyId}/pending-broadcasts`, { params });

    const sequencesMap = data.reduce((acc, workflow) => {
      acc.set(workflow.id, workflow);
      return acc;
    }, new Map());

    dispatch({
      type: PENDING_SEQUENCES_SUCCESS,
      payload: {
        sequences: sequencesMap,
        meta: {
          page,
          totalPages: Math.ceil(totalItems / params.limit)
        }
      }
    });
  } catch (error) {
    dispatch(addAlert('Unable to get pending sequences', 'error'));
  }
};

export const acceptPendingSequences = (companyId, sequenceIds) => async (dispatch, getState) => {
  dispatch({ type: ACCEPT_SEQUANCE_CALL });

  try {
    await apiFetch('patch', `admin/${companyId}/accept-pending-broadcasts`, { sequenceIds });

    const { campaigns: { pendingSequences } } = getState();
    const mutablePendingSequances = new Map(pendingSequences);
    mutablePendingSequances.delete(sequenceIds[0]);

    dispatch({
      type: ACCEPT_SEQUANCE_SUCCESS,
      payload: mutablePendingSequances
    });
  } catch (error) {
    dispatch(addAlert('Unable to accept sequence', 'error'));
  }
};

export const declinePendingSequances = (companyId, sequenceIds) => async (dispatch, getState) => {
  dispatch({ type: DECLINE_SEQUANCE_CALL });

  try {
    await apiFetch('patch', `/admin/${companyId}/decline-pending-broadcasts/`, { sequenceIds });

    const { campaigns: { pendingSequences } } = getState();
    const mutablePendingSequances = new Map(pendingSequences);
    mutablePendingSequances.delete(sequenceIds[0]);

    dispatch({
      type: DECLINE_SEQUANCE_SUCCESS,
      payload: mutablePendingSequances
    });

  } catch (error) {
    dispatch(addAlert('Unable to decline pending sequence', 'error'));
  }

};

export const acceptPendingWorkflows = (companyId, workflowIds) => async (dispatch, getState) => {
  dispatch({ type: ACCEPT_WORKFLOW_CALL });

  try {
    await apiFetch('patch', `admin/${companyId}/accept-pending-workflows`, { workflowIds });

    const { campaigns: { pendingWorkflows } } = getState();
    const mutablePendingWorkflows = new Map(pendingWorkflows);
    mutablePendingWorkflows.delete(workflowIds[0]);

    dispatch({
      type: ACCEPT_WORKFLOW_SUCCESS,
      payload: mutablePendingWorkflows
    });
  } catch (error) {
    dispatch(addAlert('Unable to accept sequence', 'error'));
  }
};

export const declinePendingWorkflows = (companyId, workflowIds) => async (dispatch, getState) => {
  dispatch({ type: DECLINE_WORKFLOW_CALL });

  try {
    await apiFetch('patch', `/admin/${companyId}/decline-pending-workflows/`, { workflowIds });

    const { campaigns: { pendingWorkflows } } = getState();
    const mutablePendingWorkflows = new Map(pendingWorkflows);
    mutablePendingWorkflows.delete(workflowIds[0]);

    dispatch({
      type: DECLINE_WORKFLOW_SUCCESS,
      payload: mutablePendingWorkflows
    });

  } catch (error) {
    dispatch(addAlert('Unable to decline pending sequence', 'error'));
  }

};