/* eslint-disable prefer-template */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';


import PaginationComponent from '../pagination';
import Loader from '../loader';

import './data-table.scss';

const dataTransform = (dataObj, dataType) => {
  switch (dataType) {
    case 'updates': {
      return dataObj.rows;
    }
    default: {
      return dataObj;
    }
  }
};


const DataTable = ({
  dataType,
  dataObj,
  paginationHandler,
  searchIt,
  isLoading,
  tableHeader,
  wrapperStyle,
  tableBodyContent
}) => {
  const data = dataTransform(dataObj, dataType);

  return (
    <>
      {isLoading && (<Loader/>)}
      <div className="table-block">
        <div className="table-header">
          {searchIt && (
            <div className="search-input">
              <span>Search</span>
              <input
                type="text"
                onKeyDown={e => e.key === 'Enter' && searchIt(e.target.value)}
                placeholder="Type"
              />
            </div>
          )}
        </div>
        <div className={`table-wrapper ${dataType !== 'updates' ? 'full-border' : ''}`}>
          <Table striped hover style={wrapperStyle}>
            <thead>
            <tr>
              {data.users && (
                <>
                  <th>User’s First Name</th>
                  <th>User’s Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Date Joined</th>
                  <th>Number of Conversations</th>
                </>
              )}

              {data.contacts && (
                <>
                  <th>User’s First Name</th>
                  <th>User’s Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Date Added</th>
                  <th>Added by Who</th>
                </>
              )}

              {dataType === 'updates' && (
                <>
                  <th>Company Name</th>
                  <th>Date Created</th>
                  <th>Company Size</th>
                </>
              )}

              {tableHeader && tableHeader()}
            </tr>
            </thead>
            <tbody>
            {data.users && !data.users.length && <tr><td colSpan="7" className="table-no-information text-center p-4 text-muted">No results found</td></tr>}
            {data.users && data.users?.map(user => (
              <tr key={user.id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{moment(user.createdAt).format('MM/DD/YYYY')}</td>
                <td>{user.conversationCount}</td>
              </tr>
            ))}

            {data.contacts && !data.contacts.length && <tr><td colSpan="7" className="table-no-information text-center p-4 text-muted">No results found</td></tr>}
            {data.contacts && data.contacts?.map(cont => (
              <tr key={cont.id}>
                <td>{cont.firstName || ''}</td>
                <td>{cont.lastName || ''}</td>
                <td>{cont.email}</td>
                <td>{cont.phone}</td>
                <td>{cont.address}</td>
                <td>{moment(cont.createdAt).format('MM/DD/YYYY')}</td>
                <td>{cont.creator_full_name}</td>
              </tr>
            ))}

            {dataType === 'updates' && data?.map(upd => (
              <tr key={upd.id}>
                <td>{upd?.additional?.companyName || ''}</td>
                <td>{moment(upd?.createdAt).format('MM/DD/YYYY') || ''}</td>
                <td>{upd?.additional?.companySize || ''}</td>
              </tr>
            ))}
            {tableBodyContent && tableBodyContent()}
            </tbody>
          </Table>
          {paginationHandler && (
            <PaginationComponent
              current={data?.page}
              last={data?.totalPages}
              handler={paginationHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line react/forbid-prop-types
DataTable.propTypes = {
  dataType: PropTypes.string,
  dataObj: PropTypes.oneOfType([PropTypes.object]),
  wrapperStyle: PropTypes.shape({}),
  tableBodyContent: PropTypes.func,
  tableHeader: PropTypes.func,
  paginationHandler: PropTypes.func,
  searchIt: PropTypes.func,
  isLoading: PropTypes.bool
};

DataTable.defaultProps = {
  dataObj: {},
  wrapperStyle: {},
  dataType: '',
  tableHeader: null,
  tableBodyContent: null,
  paginationHandler: () => {},
  searchIt: null,
  isLoading: false
};

export default DataTable;