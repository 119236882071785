export const COMPANIES_CALL = 'COMPANIES_CALL';
export const COMPANIES_RESPONSE = 'COMPANIES_RESPONSE';

export const USERS_CALL = 'USERS_CALL';
export const USERS_RESPONSE = 'USERS_RESPONSE';

export const CONTACTS_CALL = 'CONTACTS_CALL';
export const CONTACTS_RESPONSE = 'CONTACTS_RESPONSE';

export const SELECT_COMPANY = 'SELECT_COMPANY';

export const DELETE_COMPANY_CALL = 'DELETE_COMPANY_CALL';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const ACCEPT_COMPANY_CALL = 'ACCEPT_COMPANY_CALL';
export const ACCEPT_COMPANY_SUCCESS = 'ACCEPT_COMPANY_SUCCESS';
export const ACCEPT_COMPANY_FAILURE = 'ACCEPT_COMPANY_FAILURE';

export const ENTERPRISE_USER_CREATE = 'ENTERPRISE_USER_CREATE';
export const ENTERPRISE_USER_CREATE_SUCCESS = 'ENTERPRISE_USER_CREATE_SUCCESS';
export const ENTERPRISE_USER_CREATE_FAIL = 'ENTERPRISE_USER_CREATE_FAIL';
export const RESET_ENTERPRISE = 'RESET_ENTERPRISE';