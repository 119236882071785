import { apiFetch } from 'utils';
import { addAlert } from '../app/actions';
import {
  FETCH_SURVEY_CALL,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  FETCH_SURVEYS_CALL,
  FETCH_SURVEYS_SUCCESS,
  FETCH_SURVEYS_FAILURE,
  SELECT_SURVEY
} from './types';

const { REACT_APP_API_VERSION: apiVersion } = process.env;

export const setSurvey = surveyId => async dispatch => {
  dispatch({
    type: SELECT_SURVEY,
    payload: {
      surveyId
    }
  });
};

export const fetchSurveys = queryParams => async dispatch => {
  dispatch({ type: FETCH_SURVEYS_CALL });

  try {
    const data = await apiFetch('get', `/${apiVersion}/surveys`, {
      params: queryParams
    });

    dispatch({
      type: FETCH_SURVEYS_SUCCESS,
      payload: {
        ...data,
        ...data.meta
      }
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
    dispatch({ type: FETCH_SURVEYS_FAILURE });
  }
};

export const fetchSurvey = surveyId => async dispatch => {
  dispatch({ type: FETCH_SURVEY_CALL });

  try {
    const data = await apiFetch('get', `/${apiVersion}/surveys/${surveyId}`);

    dispatch({
      type: FETCH_SURVEY_SUCCESS,
      payload: data.survey
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
    dispatch({ type: FETCH_SURVEY_FAILURE });
  }
};