export const phoneAreaCodes = [
  {
    label: '201',
    value: '201'
  },
  {
    label: '202',
    value: '202'
  },
  {
    label: '203',
    value: '203'
  },
  {
    label: '204',
    value: '204'
  },
  {
    label: '205',
    value: '205'
  },
  {
    label: '206',
    value: '206'
  },
  {
    label: '207',
    value: '207'
  },
  {
    label: '208',
    value: '208'
  },
  {
    label: '209',
    value: '209'
  },
  {
    label: '210',
    value: '210'
  },
  {
    label: '211',
    value: '211'
  },
  {
    label: '212',
    value: '212'
  },
  {
    label: '213',
    value: '213'
  },
  {
    label: '214',
    value: '214'
  },
  {
    label: '215',
    value: '215'
  },
  {
    label: '216',
    value: '216'
  },
  {
    label: '217',
    value: '217'
  },
  {
    label: '218',
    value: '218'
  },
  {
    label: '219',
    value: '219'
  },
  {
    label: '220',
    value: '220'
  },
  {
    label: '223',
    value: '223'
  },
  {
    label: '224',
    value: '224'
  },
  {
    label: '225',
    value: '225'
  },
  {
    label: '226',
    value: '226'
  },
  {
    label: '228',
    value: '228'
  },
  {
    label: '229',
    value: '229'
  },
  {
    label: '231',
    value: '231'
  },
  {
    label: '234',
    value: '234'
  },
  {
    label: '236',
    value: '236'
  },
  {
    label: '239',
    value: '239'
  },
  {
    label: '240',
    value: '240'
  },
  {
    label: '242',
    value: '242'
  },
  {
    label: '246',
    value: '246'
  },
  {
    label: '248',
    value: '248'
  },
  {
    label: '250',
    value: '250'
  },
  {
    label: '251',
    value: '251'
  },
  {
    label: '252',
    value: '252'
  },
  {
    label: '253',
    value: '253'
  },
  {
    label: '254',
    value: '254'
  },
  {
    label: '256',
    value: '256'
  },
  {
    label: '260',
    value: '260'
  },
  {
    label: '262',
    value: '262'
  },
  {
    label: '264',
    value: '264'
  },
  {
    label: '267',
    value: '267'
  },
  {
    label: '268',
    value: '268'
  },
  {
    label: '269',
    value: '269'
  },
  {
    label: '270',
    value: '270'
  },
  {
    label: '272',
    value: '272'
  },
  {
    label: '276',
    value: '276'
  },
  {
    label: '278',
    value: '278'
  },
  {
    label: '281',
    value: '281'
  },
  {
    label: '283',
    value: '283'
  },
  {
    label: '284',
    value: '284'
  },
  {
    label: '289',
    value: '289'
  },
  {
    label: '301',
    value: '301'
  },
  {
    label: '302',
    value: '302'
  },
  {
    label: '303',
    value: '303'
  },
  {
    label: '304',
    value: '304'
  },
  {
    label: '305',
    value: '305'
  },
  {
    label: '306',
    value: '306'
  },
  {
    label: '307',
    value: '307'
  },
  {
    label: '308',
    value: '308'
  },
  {
    label: '309',
    value: '309'
  },
  {
    label: '310',
    value: '310'
  },
  {
    label: '311',
    value: '311'
  },
  {
    label: '312',
    value: '312'
  },
  {
    label: '313',
    value: '313'
  },
  {
    label: '314',
    value: '314'
  },
  {
    label: '315',
    value: '315'
  },
  {
    label: '316',
    value: '316'
  },
  {
    label: '317',
    value: '317'
  },
  {
    label: '318',
    value: '318'
  },
  {
    label: '319',
    value: '319'
  },
  {
    label: '320',
    value: '320'
  },
  {
    label: '321',
    value: '321'
  },
  {
    label: '323',
    value: '323'
  },
  {
    label: '325',
    value: '325'
  },
  {
    label: '330',
    value: '330'
  },
  {
    label: '331',
    value: '331'
  },
  {
    label: '332',
    value: '332'
  },
  {
    label: '334',
    value: '334'
  },
  {
    label: '336',
    value: '336'
  },
  {
    label: '337',
    value: '337'
  },
  {
    label: '339',
    value: '339'
  },
  {
    label: '340',
    value: '340'
  },
  {
    label: '341',
    value: '341'
  },
  {
    label: '343',
    value: '343'
  },
  {
    label: '345',
    value: '345'
  },
  {
    label: '346',
    value: '346'
  },
  {
    label: '347',
    value: '347'
  },
  {
    label: '351',
    value: '351'
  },
  {
    label: '352',
    value: '352'
  },
  {
    label: '360',
    value: '360'
  },
  {
    label: '361',
    value: '361'
  },
  {
    label: '365',
    value: '365'
  },
  {
    label: '369',
    value: '369'
  },
  {
    label: '380',
    value: '380'
  },
  {
    label: '385',
    value: '385'
  },
  {
    label: '386',
    value: '386'
  },
  {
    label: '401',
    value: '401'
  },
  {
    label: '402',
    value: '402'
  },
  {
    label: '403',
    value: '403'
  },
  {
    label: '404',
    value: '404'
  },
  {
    label: '405',
    value: '405'
  },
  {
    label: '406',
    value: '406'
  },
  {
    label: '407',
    value: '407'
  },
  {
    label: '408',
    value: '408'
  },
  {
    label: '409',
    value: '409'
  },
  {
    label: '410',
    value: '410'
  },
  {
    label: '411',
    value: '411'
  },
  {
    label: '412',
    value: '412'
  },
  {
    label: '413',
    value: '413'
  },
  {
    label: '414',
    value: '414'
  },
  {
    label: '415',
    value: '415'
  },
  {
    label: '416',
    value: '416'
  },
  {
    label: '417',
    value: '417'
  },
  {
    label: '418',
    value: '418'
  },
  {
    label: '419',
    value: '419'
  },
  {
    label: '423',
    value: '423'
  },
  {
    label: '424',
    value: '424'
  },
  {
    label: '425',
    value: '425'
  },
  {
    label: '430',
    value: '430'
  },
  {
    label: '431',
    value: '431'
  },
  {
    label: '432',
    value: '432'
  },
  {
    label: '434',
    value: '434'
  },
  {
    label: '435',
    value: '435'
  },
  {
    label: '437',
    value: '437'
  },
  {
    label: '438',
    value: '438'
  },
  {
    label: '440',
    value: '440'
  },
  {
    label: '441',
    value: '441'
  },
  {
    label: '442',
    value: '442'
  },
  {
    label: '443',
    value: '443'
  },
  {
    label: '450',
    value: '450'
  },
  {
    label: '456',
    value: '456'
  },
  {
    label: '458',
    value: '458'
  },
  {
    label: '464',
    value: '464'
  },
  {
    label: '469',
    value: '469'
  },
  {
    label: '470',
    value: '470'
  },
  {
    label: '473',
    value: '473'
  },
  {
    label: '475',
    value: '475'
  },
  {
    label: '478',
    value: '478'
  },
  {
    label: '479',
    value: '479'
  },
  {
    label: '480',
    value: '480'
  },
  {
    label: '481',
    value: '481'
  },
  {
    label: '484',
    value: '484'
  },
  {
    label: '500',
    value: '500'
  },
  {
    label: '501',
    value: '501'
  },
  {
    label: '502',
    value: '502'
  },
  {
    label: '503',
    value: '503'
  },
  {
    label: '504',
    value: '504'
  },
  {
    label: '505',
    value: '505'
  },
  {
    label: '506',
    value: '506'
  },
  {
    label: '507',
    value: '507'
  },
  {
    label: '508',
    value: '508'
  },
  {
    label: '509',
    value: '509'
  },
  {
    label: '510',
    value: '510'
  },
  {
    label: '511',
    value: '511'
  },
  {
    label: '512',
    value: '512'
  },
  {
    label: '513',
    value: '513'
  },
  {
    label: '514',
    value: '514'
  },
  {
    label: '515',
    value: '515'
  },
  {
    label: '516',
    value: '516'
  },
  {
    label: '517',
    value: '517'
  },
  {
    label: '518',
    value: '518'
  },
  {
    label: '519',
    value: '519'
  },
  {
    label: '520',
    value: '520'
  },
  {
    label: '530',
    value: '530'
  },
  {
    label: '539',
    value: '539'
  },
  {
    label: '540',
    value: '540'
  },
  {
    label: '541',
    value: '541'
  },
  {
    label: '548',
    value: '548'
  },
  {
    label: '551',
    value: '551'
  },
  {
    label: '555',
    value: '555'
  },
  {
    label: '557',
    value: '557'
  },
  {
    label: '559',
    value: '559'
  },
  {
    label: '561',
    value: '561'
  },
  {
    label: '562',
    value: '562'
  },
  {
    label: '563',
    value: '563'
  },
  {
    label: '564',
    value: '564'
  },
  {
    label: '567',
    value: '567'
  },
  {
    label: '570',
    value: '570'
  },
  {
    label: '571',
    value: '571'
  },
  {
    label: '573',
    value: '573'
  },
  {
    label: '574',
    value: '574'
  },
  {
    label: '575',
    value: '575'
  },
  {
    label: '579',
    value: '579'
  },
  {
    label: '580',
    value: '580'
  },
  {
    label: '581',
    value: '581'
  },
  {
    label: '585',
    value: '585'
  },
  {
    label: '586',
    value: '586'
  },
  {
    label: '587',
    value: '587'
  },
  {
    label: '600',
    value: '600'
  },
  {
    label: '601',
    value: '601'
  },
  {
    label: '602',
    value: '602'
  },
  {
    label: '603',
    value: '603'
  },
  {
    label: '604',
    value: '604'
  },
  {
    label: '605',
    value: '605'
  },
  {
    label: '606',
    value: '606'
  },
  {
    label: '607',
    value: '607'
  },
  {
    label: '608',
    value: '608'
  },
  {
    label: '609',
    value: '609'
  },
  {
    label: '610',
    value: '610'
  },
  {
    label: '611',
    value: '611'
  },
  {
    label: '612',
    value: '612'
  },
  {
    label: '613',
    value: '613'
  },
  {
    label: '614',
    value: '614'
  },
  {
    label: '615',
    value: '615'
  },
  {
    label: '616',
    value: '616'
  },
  {
    label: '617',
    value: '617'
  },
  {
    label: '618',
    value: '618'
  },
  {
    label: '619',
    value: '619'
  },
  {
    label: '620',
    value: '620'
  },
  {
    label: '623',
    value: '623'
  },
  {
    label: '626',
    value: '626'
  },
  {
    label: '627',
    value: '627'
  },
  {
    label: '628',
    value: '628'
  },
  {
    label: '629',
    value: '629'
  },
  {
    label: '630',
    value: '630'
  },
  {
    label: '631',
    value: '631'
  },
  {
    label: '636',
    value: '636'
  },
  {
    label: '639',
    value: '639'
  },
  {
    label: '641',
    value: '641'
  },
  {
    label: '646',
    value: '646'
  },
  {
    label: '647',
    value: '647'
  },
  {
    label: '649',
    value: '649'
  },
  {
    label: '650',
    value: '650'
  },
  {
    label: '651',
    value: '651'
  },
  {
    label: '657',
    value: '657'
  },
  {
    label: '660',
    value: '660'
  },
  {
    label: '661',
    value: '661'
  },
  {
    label: '662',
    value: '662'
  },
  {
    label: '664',
    value: '664'
  },
  {
    label: '667',
    value: '667'
  },
  {
    label: '669',
    value: '669'
  },
  {
    label: '670',
    value: '670'
  },
  {
    label: '671',
    value: '671'
  },
  {
    label: '678',
    value: '678'
  },
  {
    label: '679',
    value: '679'
  },
  {
    label: '681',
    value: '681'
  },
  {
    label: '682',
    value: '682'
  },
  {
    label: '684',
    value: '684'
  },
  {
    label: '689',
    value: '689'
  },
  {
    label: '700',
    value: '700'
  },
  {
    label: '701',
    value: '701'
  },
  {
    label: '702',
    value: '702'
  },
  {
    label: '703',
    value: '703'
  },
  {
    label: '704',
    value: '704'
  },
  {
    label: '705',
    value: '705'
  },
  {
    label: '706',
    value: '706'
  },
  {
    label: '707',
    value: '707'
  },
  {
    label: '708',
    value: '708'
  },
  {
    label: '709',
    value: '709'
  },
  {
    label: '710',
    value: '710'
  },
  {
    label: '711',
    value: '711'
  },
  {
    label: '712',
    value: '712'
  },
  {
    label: '713',
    value: '713'
  },
  {
    label: '714',
    value: '714'
  },
  {
    label: '715',
    value: '715'
  },
  {
    label: '716',
    value: '716'
  },
  {
    label: '717',
    value: '717'
  },
  {
    label: '718',
    value: '718'
  },
  {
    label: '719',
    value: '719'
  },
  {
    label: '720',
    value: '720'
  },
  {
    label: '721',
    value: '721'
  },
  {
    label: '724',
    value: '724'
  },
  {
    label: '725',
    value: '725'
  },
  {
    label: '727',
    value: '727'
  },
  {
    label: '731',
    value: '731'
  },
  {
    label: '732',
    value: '732'
  },
  {
    label: '734',
    value: '734'
  },
  {
    label: '737',
    value: '737'
  },
  {
    label: '740',
    value: '740'
  },
  {
    label: '743',
    value: '743'
  },
  {
    label: '747',
    value: '747'
  },
  {
    label: '754',
    value: '754'
  },
  {
    label: '757',
    value: '757'
  },
  {
    label: '758',
    value: '758'
  },
  {
    label: '760',
    value: '760'
  },
  {
    label: '762',
    value: '762'
  },
  {
    label: '763',
    value: '763'
  },
  {
    label: '764',
    value: '764'
  },
  {
    label: '765',
    value: '765'
  },
  {
    label: '767',
    value: '767'
  },
  {
    label: '769',
    value: '769'
  },
  {
    label: '770',
    value: '770'
  },
  {
    label: '772',
    value: '772'
  },
  {
    label: '773',
    value: '773'
  },
  {
    label: '774',
    value: '774'
  },
  {
    label: '775',
    value: '775'
  },
  {
    label: '778',
    value: '778'
  },
  {
    label: '779',
    value: '779'
  },
  {
    label: '780',
    value: '780'
  },
  {
    label: '781',
    value: '781'
  },
  {
    label: '782',
    value: '782'
  },
  {
    label: '784',
    value: '784'
  },
  {
    label: '785',
    value: '785'
  },
  {
    label: '786',
    value: '786'
  },
  {
    label: '787',
    value: '787'
  },
  {
    label: '800',
    value: '800'
  },
  {
    label: '801',
    value: '801'
  },
  {
    label: '802',
    value: '802'
  },
  {
    label: '803',
    value: '803'
  },
  {
    label: '804',
    value: '804'
  },
  {
    label: '805',
    value: '805'
  },
  {
    label: '806',
    value: '806'
  },
  {
    label: '807',
    value: '807'
  },
  {
    label: '808',
    value: '808'
  },
  {
    label: '809',
    value: '809'
  },
  {
    label: '810',
    value: '810'
  },
  {
    label: '811',
    value: '811'
  },
  {
    label: '812',
    value: '812'
  },
  {
    label: '813',
    value: '813'
  },
  {
    label: '814',
    value: '814'
  },
  {
    label: '815',
    value: '815'
  },
  {
    label: '816',
    value: '816'
  },
  {
    label: '817',
    value: '817'
  },
  {
    label: '818',
    value: '818'
  },
  {
    label: '819',
    value: '819'
  },
  {
    label: '822',
    value: '822'
  },
  {
    label: '825',
    value: '825'
  },
  {
    label: '828',
    value: '828'
  },
  {
    label: '829',
    value: '829'
  },
  {
    label: '830',
    value: '830'
  },
  {
    label: '831',
    value: '831'
  },
  {
    label: '832',
    value: '832'
  },
  {
    label: '833',
    value: '833'
  },
  {
    label: '835',
    value: '835'
  },
  {
    label: '843',
    value: '843'
  },
  {
    label: '844',
    value: '844'
  },
  {
    label: '845',
    value: '845'
  },
  {
    label: '847',
    value: '847'
  },
  {
    label: '848',
    value: '848'
  },
  {
    label: '849',
    value: '849'
  },
  {
    label: '850',
    value: '850'
  },
  {
    label: '855',
    value: '855'
  },
  {
    label: '856',
    value: '856'
  },
  {
    label: '857',
    value: '857'
  },
  {
    label: '858',
    value: '858'
  },
  {
    label: '859',
    value: '859'
  },
  {
    label: '860',
    value: '860'
  },
  {
    label: '862',
    value: '862'
  },
  {
    label: '863',
    value: '863'
  },
  {
    label: '864',
    value: '864'
  },
  {
    label: '865',
    value: '865'
  },
  {
    label: '866',
    value: '866'
  },
  {
    label: '867',
    value: '867'
  },
  {
    label: '868',
    value: '868'
  },
  {
    label: '869',
    value: '869'
  },
  {
    label: '870',
    value: '870'
  },
  {
    label: '872',
    value: '872'
  },
  {
    label: '873',
    value: '873'
  },
  {
    label: '876',
    value: '876'
  },
  {
    label: '877',
    value: '877'
  },
  {
    label: '878',
    value: '878'
  },
  {
    label: '880',
    value: '880'
  },
  {
    label: '881',
    value: '881'
  },
  {
    label: '882',
    value: '882'
  },
  {
    label: '888',
    value: '888'
  },
  {
    label: '898',
    value: '898'
  },
  {
    label: '900',
    value: '900'
  },
  {
    label: '901',
    value: '901'
  },
  {
    label: '902',
    value: '902'
  },
  {
    label: '903',
    value: '903'
  },
  {
    label: '904',
    value: '904'
  },
  {
    label: '905',
    value: '905'
  },
  {
    label: '906',
    value: '906'
  },
  {
    label: '907',
    value: '907'
  },
  {
    label: '908',
    value: '908'
  },
  {
    label: '909',
    value: '909'
  },
  {
    label: '910',
    value: '910'
  },
  {
    label: '911',
    value: '911'
  },
  {
    label: '912',
    value: '912'
  },
  {
    label: '913',
    value: '913'
  },
  {
    label: '914',
    value: '914'
  },
  {
    label: '915',
    value: '915'
  },
  {
    label: '916',
    value: '916'
  },
  {
    label: '917',
    value: '917'
  },
  {
    label: '918',
    value: '918'
  },
  {
    label: '919',
    value: '919'
  },
  {
    label: '920',
    value: '920'
  },
  {
    label: '925',
    value: '925'
  },
  {
    label: '927',
    value: '927'
  },
  {
    label: '928',
    value: '928'
  },
  {
    label: '929',
    value: '929'
  },
  {
    label: '931',
    value: '931'
  },
  {
    label: '935',
    value: '935'
  },
  {
    label: '936',
    value: '936'
  },
  {
    label: '937',
    value: '937'
  },
  {
    label: '939',
    value: '939'
  },
  {
    label: '940',
    value: '940'
  },
  {
    label: '941',
    value: '941'
  },
  {
    label: '947',
    value: '947'
  },
  {
    label: '949',
    value: '949'
  },
  {
    label: '951',
    value: '951'
  },
  {
    label: '952',
    value: '952'
  },
  {
    label: '954',
    value: '954'
  },
  {
    label: '956',
    value: '956'
  },
  {
    label: '957',
    value: '957'
  },
  {
    label: '959',
    value: '959'
  },
  {
    label: '970',
    value: '970'
  },
  {
    label: '971',
    value: '971'
  },
  {
    label: '972',
    value: '972'
  },
  {
    label: '973',
    value: '973'
  },
  {
    label: '975',
    value: '975'
  },
  {
    label: '976',
    value: '976'
  },
  {
    label: '978',
    value: '978'
  },
  {
    label: '979',
    value: '979'
  },
  {
    label: '980',
    value: '980'
  },
  {
    label: '984',
    value: '984'
  },
  {
    label: '985',
    value: '985'
  },
  {
    label: '989',
    value: '989'
  },
  {
    label: '999',
    value: '999'
  }
];