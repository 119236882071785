import React from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { userSelector } from 'store/user/selectors';
import { logoutUser } from 'store/user/actions';

import './header.scss';

const Header = () => {

  const dispatch = useDispatch();
  const user = useSelector(userSelector, isEqual);
  // console.log(user)

  // const { active, avatar, email, tempFirstName, tempLastName, tempPhone } = user

  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <header className="d-flex align-items-center">
      <Dropdown className="user-info ml-auto">
        <Dropdown.Toggle className="user-button">
          <div className="user-image" style={{ backgroundImage: `url(${user?.avatar || 'https://image.flaticon.com/icons/png/512/21/21104.png'})` }} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.ItemText className="username">
            Super Admin
          </Dropdown.ItemText>
          {/* <Dropdown.ItemText className="company">Company name</Dropdown.ItemText> */}
          {/* <Dropdown.Item href="#/action-1">Edit My Profile</Dropdown.Item> */}
          {/* <Dropdown.Item href="#/action-2">Manage Subscription</Dropdown.Item> */}
          <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </header>
  );
};

export default Header;