import {
  COMPANIES_CALL,
  COMPANIES_RESPONSE,
  USERS_CALL,
  USERS_RESPONSE,
  CONTACTS_CALL,
  CONTACTS_RESPONSE,
  SELECT_COMPANY,
  DELETE_COMPANY_CALL,
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_SUCCESS,
  ACCEPT_COMPANY_CALL,
  ACCEPT_COMPANY_FAILURE,
  ACCEPT_COMPANY_SUCCESS,
  ENTERPRISE_USER_CREATE,
  ENTERPRISE_USER_CREATE_SUCCESS,
  ENTERPRISE_USER_CREATE_FAIL,
  RESET_ENTERPRISE
} from './types';

const initialState = {
  selectedCompanyId: undefined,

  isCompaniesFetched: false,
  isCompaniesLoading: false,
  companiesData: {},

  isUsersFetched: false,
  isUsersLoading: false,
  usersData: {},

  isContactsFetched: false,
  isContactsLoading: false,
  contactsData: {},

  enterpriseContactCreateLoading: false,
  isEnterpriseContactCreated: false
};

const companiesReducer = (state = initialState, {
  type,
  payload
}) => {
  const newState = { ...state };

  switch (type) {
    case SELECT_COMPANY: {
      const findCompany = newState.companiesData?.companies?.find(comp => comp.id === payload?.companyId);

      if (findCompany) {
        newState.selectedCompanyId = payload.companyId;
        return newState;
      }
      return state;
    }
    case COMPANIES_CALL: {
      // newState.isCompaniesFetched = false;
      newState.isCompaniesLoading = true;
      // newState.companiesData = {};

      return newState;
    }
    case DELETE_COMPANY_CALL: {
      // newState.isCompaniesFetched = false;
      newState.isCompaniesLoading = true;
      // newState.companiesData = {};

      return newState;
    }
    case DELETE_COMPANY_SUCCESS: {
      newState.isCompaniesLoading = false;
      return newState;
    }
    case DELETE_COMPANY_FAILURE: {
      newState.isCompaniesLoading = false;
      return newState;
    }
    case ACCEPT_COMPANY_CALL: {
      newState.isCompaniesLoading = true;

      return newState;
    }
    case ACCEPT_COMPANY_SUCCESS: {
      newState.isCompaniesLoading = false;
      return newState;
    }
    case ACCEPT_COMPANY_FAILURE: {
      newState.isCompaniesLoading = false;
      return newState;
    }
    case COMPANIES_RESPONSE: {
      newState.isCompaniesFetched = true;
      newState.isCompaniesLoading = false;

      if (payload?.companiesData) {
        newState.companiesData = { ...payload.companiesData };

        if (payload.companiesData?.companies?.length > 0) {
          newState.selectedCompanyId = payload.companiesData.companies[0].id;
        }
      }

      return newState;
    }
    case USERS_CALL: {
      // newState.isUsersFetched = false;
      newState.isUsersLoading = true;
      // newState.usersData = {};

      return newState;
    }
    case USERS_RESPONSE: {
      newState.isUsersFetched = true;
      newState.isUsersLoading = false;

      if (payload?.usersData) {
        newState.usersData = { ...payload.usersData };
      }

      return newState;
    }
    case CONTACTS_CALL: {
      // newState.isContactsFetched = false;
      newState.isContactsLoading = true;
      // newState.contactsData = {};

      return newState;
    }
    case CONTACTS_RESPONSE: {
      newState.isContactsFetched = true;
      newState.isContactsLoading = false;

      if (payload?.contactsData) {
        newState.contactsData = { ...payload.contactsData };
      }

      return newState;
    }
    case ENTERPRISE_USER_CREATE: {
      newState.enterpriseContactCreateLoading = true;
      return newState;
    }
    case ENTERPRISE_USER_CREATE_SUCCESS: {
      newState.enterpriseContactCreateLoading = false;
      newState.isEnterpriseContactCreated = true;
      return newState;
    }
    case ENTERPRISE_USER_CREATE_FAIL: {
      newState.enterpriseContactCreateLoading = false;
      newState.isEnterpriseContactCreated = false;
      return newState;
    }
    case RESET_ENTERPRISE: {
      newState.enterpriseContactCreateLoading = false;
      newState.isEnterpriseContactCreated = false;
      return newState;
    }
    default:
      return state;
  }
};

export default companiesReducer;