const integrationServices = [
  {
    label: 'None',
    value: ''
  },
  {
    label: 'Tracker RMS',
    value: 'trackerrms'
  }
];

export default integrationServices;