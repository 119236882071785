import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { alertFirstSelector } from '../../store/app/selectors';
import { removeAlert } from '../../store/app/actions';
import { useDidUpdate } from '../../hooks';

import './alert.scss';

const Alert = () => {
  const dispatch = useDispatch();
  // const alertsList = useSelector(alertListSelector, isEqual)
  const alert = useSelector(alertFirstSelector, isEqual);
  const [isVisible, setIsVisible] = useState(false);
  const firstAlert = alert ? { ...alert } : null;

  if (firstAlert && !firstAlert.duration) {
    firstAlert.duration = 4;
  }

  // eslint-disable-next-line consistent-return
  useDidUpdate(() => {
    if (firstAlert) {
      let seconds = 0;

      // setIsVisible(true)
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 0);

      const interval = setInterval(() => {
        if (seconds === firstAlert.duration) {
          setIsVisible(false);
        }

        if (seconds >= firstAlert.duration + 1) {
          clearInterval(interval);
          dispatch(removeAlert(firstAlert.id));
        } else {
          seconds += 1;
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
  }, [firstAlert?.id]);

  return (
    <div className="alert-back">
      {firstAlert && (
        <div className={classNames(
          'alert-block',
          firstAlert.type,
          { isVisible }
        )}
        >
          <p>{firstAlert.message.toString()}</p>
        </div>
      )}
    </div>
  );
};

// Alert.propTypes = {
//   type: PropTypes.oneOf(['notification', 'error', 'warning', 'success']),
//   message: PropTypes.string.isRequired,
//   duration: PropTypes.number,
// }
//
// Alert.defaultProps = {
//   type: 'notification',
//   duration: 3,
// }

// const Alert = ({
//   type,
//   message,
//   duration,
// }) => useMemo(() => {
//   let seconds = 0
//
//   const interval = setInterval(() => {
//     seconds += 1
//   }, 1000)
//
//   console.log(seconds)
//
//   return (
//     <div className="alert-back">
//       <div className={`alert-block ${type}`}>
//         <p>{message}</p>
//       </div>
//     </div>
//   )
// }, [type, message, duration])
//
// Alert.propTypes = {
//   type: PropTypes.oneOf(['notification', 'error', 'warning', 'success']),
//   message: PropTypes.string.isRequired,
//   duration: PropTypes.number,
// }
//
// Alert.defaultProps = {
//   type: 'notification',
//   duration: 3,
// }

export default Alert;