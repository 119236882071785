import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { Modal, Button } from 'react-bootstrap';

import { surveyDataSelector, selectedSurveyIdSelector } from 'store/surveys/selectors';
import { fetchSurvey, setSurvey } from '../../store/surveys/actions';

import Loader from '../../components/loader';

const requiredField = {
  email: 'Email',
  phone: 'Phone Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  timezone: 'Time zone'
};

export const SurveyModal = () => {
  const dispatch = useDispatch();
  const selectedSurveyId = useSelector(selectedSurveyIdSelector, shallowEqual);

  const { isSurveyLoading, surveyData } = useSelector(surveyDataSelector, isEqual);

  const handleClose = () => dispatch(setSurvey(null));

  useEffect(() => {
    if (selectedSurveyId) {
      dispatch(fetchSurvey(selectedSurveyId));
    }
  }, [selectedSurveyId]);

  if (!selectedSurveyId) {
    return '';
  }

  return (
    <Modal scrollable={!!surveyData?.survey_answers} show backdrop="static">
      <Modal.Header><h5>Survey details</h5></Modal.Header>
      <Modal.Body className="border-0 py-5">
        {isSurveyLoading && <Loader />}
        {surveyData && !isSurveyLoading && Object.keys(requiredField).map(key => (
          <div key={key} className="m-2 p-2">
            <div className="p-1">
              <strong>{requiredField[key]}</strong>
            </div>
            <p className="p-1">{surveyData[key]}</p>
          </div>
        ))}
        {surveyData?.survey_answers?.map(elem => (
          <div key={elem.question?.key} className="m-2 p-2">
            <div className="p-1">
              <strong>{elem.question?.text}</strong>
            </div>
            <div className="p-1">{elem.answer}</div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isSurveyLoading}
          onClick={handleClose}
          variant="gray"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};