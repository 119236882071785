export const companiesDataSelector = state => ({
  isCompaniesFetched: state.companiesData.isCompaniesFetched,
  isCompaniesLoading: state.companiesData.isCompaniesLoading,
  companiesData: state.companiesData.companiesData
});

export const usersDataSelector = state => ({
  isUsersFetched: state.companiesData.isUsersFetched,
  isUsersLoading: state.companiesData.isUsersLoading,
  usersData: state.companiesData.usersData
});

export const contactsDataSelector = state => ({
  isContactsFetched: state.companiesData.isContactsFetched,
  isContactsLoading: state.companiesData.isContactsLoading,
  contactsData: state.companiesData.contactsData
});

export const enterpriseDataSelector = ({ companiesData }) => ({
  loading: companiesData.enterpriseContactCreateLoading,
  isCreated: companiesData.isEnterpriseContactCreated
});

export const selectedCompanyIdSelector = state => state.companiesData.selectedCompanyId;