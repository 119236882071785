import { apiFetch } from 'utils';
import { STATISTICS_CALL, STATISTICS_RESPONSE } from './types';
import { addAlert } from '../app/actions';

export const callStatistics = queryParams => async dispatch => {
  dispatch({ type: STATISTICS_CALL });

  let data;
  try {
    data = await apiFetch('get', '/admin/dashboard', {
      params: queryParams
    });
  } catch (error) {
    dispatch(addAlert(error.message, 'error'));
  }

  dispatch({
    type: STATISTICS_RESPONSE,
    ...data && {
      payload: {
        statistics: data
      }
    }
  });
};