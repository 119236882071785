import {
  USER_LOGIN,
  USER_LOGIN_RESPONSE,
  USER_LOGOUT
} from './types';

const defaultInitialState = {
  user: null,
  companies: [],
  activationToken: '',
  isLoading: false,
  authorized: null
};

const initialState = {
  ...defaultInitialState,
  activationToken: localStorage.getItem('activationToken')
};

const userReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case USER_LOGIN: {
      return { ...defaultInitialState, isLoading: true };
    }

    case USER_LOGIN_RESPONSE: {
      const newState = {
        ...state,
        isLoading: false,
        authorized: false
      };

      if (payload?.user?.isSuperAdmin) {
          newState.user = { ...payload.user };
          newState.companies = [...payload.user.companies];
          newState.activationToken = payload.activationToken;
          newState.authorized = true;
      }
      return newState;
    }

    case USER_LOGOUT: {
      return { ...defaultInitialState, authorized: false };
    }

    default:
      return state;
  }
};

export default userReducer;