import React, { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';

import './pagination.scss';

const PaginationComponent = ({
  current,
  last,
  handler
}) => useMemo(() => (
  <Pagination>
    <Pagination.First onClick={() => handler(1)}/>

    <Pagination.Prev disabled={current === 1} onClick={() => handler(current - 1)}/>
    {(last >= 3 && current >= 3) && (
      <Pagination.Item onClick={() => handler(1)}>
        1
      </Pagination.Item>
    )}
    {(last > 3 && current > 3) && (
      <Pagination.Ellipsis/>
    )}
    {current >= 2 && (
      <Pagination.Item onClick={() => handler(current - 1)}>
        {current - 1}
      </Pagination.Item>
    )}
    <Pagination.Item active>
      {current}
    </Pagination.Item>
    {current < last && (
      <Pagination.Item onClick={() => handler(current + 1)}>
        {current + 1}
      </Pagination.Item>
    )}
    {(last > 3 && current < last - 2) && (
      <Pagination.Ellipsis/>
    )}
    {(last > 2 && current <= last - 2) && (
      <Pagination.Item onClick={() => handler(last)}>
        {last}
      </Pagination.Item>
    )}
    <Pagination.Next disabled={current === last} onClick={() => handler(current + 1)}/>

    <Pagination.Last onClick={() => handler(last)}/>
  </Pagination>
), [current, last]);

export default PaginationComponent;