import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const initialState = {
  statisticsData: {
    isFetched: true,
    isLoading: false,
    statistics: {}
    // statistics: {
    //   isLoading: false,
    //   companiesStatistic: [
    //     {
    //       companiesCount: 1,
    //       createdAt: '2021-01-14'
    //     },
    //     {
    //       companiesCount: 2,
    //       createdAt: '2021-01-15'
    //     },
    //     {
    //       companiesCount: 5,
    //       createdAt: '2021-02-16'
    //     },
    //     {
    //       companiesCount: 2,
    //       createdAt: '2021-02-17'
    //     },
    //     {
    //       companiesCount: 12,
    //       createdAt: '2021-02-18'
    //     },
    //     {
    //       companiesCount: 2,
    //       createdAt: '2021-02-19'
    //     },
    //     {
    //       companiesCount: 6,
    //       createdAt: '2021-02-18'
    //     },
    //     {
    //       companiesCount: 9,
    //       createdAt: '2021-02-19'
    //     }
    //   ],
    //   usersStatistic: [
    //     {
    //       usersCount: 2,
    //       createdAt: '2021-01-15'
    //     },
    //     {
    //       usersCount: 1,
    //       createdAt: '2021-02-16'
    //     },
    //     {
    //       usersCount: 4,
    //       createdAt: '2021-02-17'
    //     },
    //     {
    //       usersCount: 10,
    //       createdAt: '2021-02-18'
    //     },
    //     {
    //       usersCount: 12,
    //       createdAt: '2021-02-19'
    //     },
    //     {
    //       usersCount: 14,
    //       createdAt: '2021-02-19'
    //     },
    //     {
    //       usersCount: 10,
    //       createdAt: '2021-02-19'
    //     },
    //     {
    //       usersCount: 8,
    //       createdAt: '2021-02-19'
    //     }
    //   ],
    //   contactsCount: [
    //     {
    //       contactsCount: 2220,
    //       createdAt: '2021-02-02'
    //     },
    //     {
    //       contactsCount: 10864,
    //       createdAt: '2021-02-03'
    //     },
    //     {
    //       contactsCount: 9448,
    //       createdAt: '2021-02-04'
    //     },
    //     {
    //       contactsCount: 5448,
    //       createdAt: '2021-02-04'
    //     },
    //     {
    //       contactsCount: 3448,
    //       createdAt: '2021-02-04'
    //     },
    //     {
    //       contactsCount: 4200,
    //       createdAt: '2021-02-04'
    //     },
    //     {
    //       contactsCount: 17254,
    //       createdAt: '2021-02-04'
    //     },
    //     {
    //       contactsCount: 15267,
    //       createdAt: '2021-02-04'
    //     }
    //   ]
    // }
  }
};

const middlewares = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middlewares))
);

export default store;