import { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const dataForChart = (data = [], valueKey, datasetConfig) => useMemo(() => {
  const labels = data.map(obj => moment(obj.createdAt).format('h A, ddd, MMM DD'));
  const chartData = data.map(obj => obj[valueKey]);

  const maxValue = Math.max(...chartData);
  const maxThreshold = Math.floor(maxValue + ((maxValue / 100) * 40));

  return {
    chartData: {
      labels,
      datasets: [
        {
          data: chartData,
          fill: true,
          ...datasetConfig
        }
      ]
    },
    maxThreshold
  };
}, [data, valueKey, datasetConfig]);

dataForChart.PropTypes = {
  data: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  datasetConfig: PropTypes.shape({}).isRequired
};

export default dataForChart;