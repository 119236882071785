import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isUserAuthorized } from 'store/user/selectors';
import Login from 'pages/login';
import MainLayout from 'containers/layouts';
import { checkAuthentication } from 'store/user/actions';
import Loader from 'components/loader';
import Alert from '../alert';

const App = () => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(isUserAuthorized);

  useEffect(() => {
    dispatch(checkAuthentication());
  }, []);

  return (
    <>
      <Alert />
      <Switch>
        {isAuthorized === true && (
          <Route
            path="/"
            render={props => <MainLayout {...props} />}
          />
        )}
        {isAuthorized === false && (
          <>
            <Route path="/login" component={Login} />
            <Redirect to="/login" />
          </>
        )}
        {isAuthorized === null && <Loader />}
      </Switch>
    </>
  );
};

export default App;