import {
  COMPANY_CALL,
  COMPANY_RESPONSE_FAILURE,
  COMPANY_RESPONSE_SUCCESS,
  MESSAGES_CALL,
  MESSAGES_RESPONSE_FAILURE,
  MESSAGES_RESPONSE_SUCCESS,
  ACCEPT_MESSAGE_SUCCESS,
  ACCEPT_MESSAGE_CALL,
  DECLINE_MESSAGE_CALL,
  DECLINE_MESSAGE_SUCCESS
} from './types';

const initialState = {
  selectedCompanyId: undefined,

  isCompanyFetched: false,
  isCompanyLoading: false,
  companyData: {},

  isMessagesFetched: false,
  isMessagesLoading: false,
  pendingMessages: new Map(),
  pendingMessagesMeta: {
    totalItems: 0,
    limit: 10,
    page: 1
  },

  loaders: {
    review: false
  }
};

const messagesReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case COMPANY_CALL: return {
      ...state,
      isCompanyFetched: false,
      isCompanyLoading: true,
      companyData: {}
    };
    case COMPANY_RESPONSE_SUCCESS: return {
      ...state,
      isCompanyFetched: true,
      isCompanyLoading: false,
      companyData: payload?.company
    };
    case COMPANY_RESPONSE_FAILURE: return {
      ...state,
      isCompanyFetched: false,
      isCompanyLoading: false
    };
    case MESSAGES_CALL: return {
      ...state,
      isMessagesFetched: true,
      isMessagesLoading: true
    };
    case MESSAGES_RESPONSE_SUCCESS: return {
      ...state,
      isMessagesFetched: true,
      isMessagesLoading: false,
      pendingMessages: payload.messages,
      pendingMessagesMeta: payload.meta
    };
    case MESSAGES_RESPONSE_FAILURE: return {
      ...state,
      isMessagesFetched: false,
      isMessagesLoading: false
    };
    case ACCEPT_MESSAGE_CALL: return {
      ...state,
      loaders: {
        ...state.loaders,
        review: true
      }
    };
    case ACCEPT_MESSAGE_SUCCESS:
    case DECLINE_MESSAGE_SUCCESS: return {
      ...state,
      pendingMessages: payload,
      loaders: {
        ...state.loaders,
        review: false
      }
    };
    case DECLINE_MESSAGE_CALL: return {
      ...state,
      loaders: {
        ...state.loaders,
        review: true
      }
    };
    default:
      return state;
  }
};

export default messagesReducer;