import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { label, radio, checkmark } from './radio-button.module.scss';

export const RadioButton = ({ name, labelText, value, className, onChange }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={classNames(className, label)}>
    <input name={name} type="radio" value={value} className={radio} onChange={onChange} />
    <span className={checkmark} />
    <span className="d-inline-block ml-2">{labelText}</span>
  </label>
);

RadioButton.defaultProps = {
  className: ''
};

RadioButton.propTypes = {
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};