export const PENDING_WORKFLOWS_SUCCESS = 'PENDING_WORKFLOWS_SUCCESS';

export const PENDING_SEQUENCES_SUCCESS = 'PENDING_SEQUENCES_SUCCESS';

export const ACCEPT_SEQUANCE_CALL = 'ACCEPT_SEQUANCE_CALL';
export const ACCEPT_SEQUANCE_SUCCESS = 'ACCEPT_SEQUANCE_SUCCESS';

export const ACCEPT_WORKFLOW_CALL = 'ACCEPT_WORKFLOW_CALL';
export const ACCEPT_WORKFLOW_SUCCESS = 'ACCEPT_WORKFLOW_SUCCESS';

export const DECLINE_SEQUANCE_CALL = 'ACCEPT_SEQUANCE_CALL';
export const DECLINE_SEQUANCE_SUCCESS = 'ACCEPT_SEQUANCE_SUCCESS';

export const DECLINE_WORKFLOW_CALL = 'ACCEPT_WORKFLOW_CALL';
export const DECLINE_WORKFLOW_SUCCESS = 'ACCEPT_WORKFLOW_SUCCESS';