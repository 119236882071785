import {
  FETCH_SURVEY_CALL,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  FETCH_SURVEYS_CALL,
  FETCH_SURVEYS_SUCCESS,
  FETCH_SURVEYS_FAILURE,
  SELECT_SURVEY
} from './types';

const initialState = {
  selectedSurveyId: undefined,

  isSurveysFetched: false,
  isSurveysLoading: false,
  surveysData: {},

  isSurveyFetched: false,
  isSurveyLoading: false,
  surveyData: null
};

const surveysReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECT_SURVEY: {
      if (payload?.surveyId) {
        const foundSurvey = state.surveysData?.surveys?.find(survey => survey.id === payload?.surveyId);

        if (foundSurvey) {
          return {
            ...state,
            selectedSurveyId: payload.surveyId
          };
        }
      }

      return {
        ...state,
        selectedSurveyId: null
      };
    }

    case FETCH_SURVEYS_CALL: return {
      ...state,
      isSurveysLoading: true,
      surveysData: {}
    };

    case FETCH_SURVEYS_SUCCESS: return {
      ...state,
      isSurveysLoading: false,
      isSurveysFetched: true,
      surveysData: payload
    };

    case FETCH_SURVEYS_FAILURE: return {
      ...state,
      isSurveysLoading: false,
      isSurveysFetched: false
    };

    case FETCH_SURVEY_CALL: return {
      ...state,
      isSurveyLoading: true,
      surveyData: {}
    };

    case FETCH_SURVEY_SUCCESS: return {
      ...state,
      isSurveyLoading: false,
      isSurveyFetched: true,
      surveyData: payload
    };

    case FETCH_SURVEY_FAILURE: return {
      ...state,
      isSurveyLoading: false,
      isSurveyFetched: false
    };

    default:
      return state;
  }
};

export default surveysReducer;