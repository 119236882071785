import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { socketInstance } from 'utils';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import Dashboard from 'pages/dashboard';
import Companies from 'pages/companies';
import MessagesData from 'pages/messages';
import Surveys from 'pages/surveys';
import EnterpriseClient from 'pages/enterprise-client';
import Updates from 'pages/updates';
import { useDispatch } from 'react-redux';
import { addUpdate } from 'store/updates/actions';

import { Campaigns } from 'pages';

const MainLayout = () => {

    const dispatch = useDispatch();
    // const { pathname } = useLocation();

    useEffect(() => {
        const socket = socketInstance();

        if (socket) {
            socket.on('error', err => {
                console.log(err instanceof Error); // true
                console.log(err.message); // not authorized
                console.log(err.data); // { content: "Please retry later" }
            });

            socket.on('connect_error', err => {
                console.log(err instanceof Error); // true
                console.log(err.message); // not authorized
                console.log(err.data); // { content: "Please retry later" }
            });

            const onEvent = socket.onevent;
            // eslint-disable-next-line func-names
            socket.onevent = function (packet) {
                const args = packet.data || [];
                onEvent.call(this, packet);
                // eslint-disable-next-line no-param-reassign
                packet.data = ['*'].concat(args);
                onEvent.call(this, packet);
            };

            socket.on('*', ({ type, data }) => {
                if (type === 'notification') {
                    dispatch(addUpdate(data));
                }
            });
        }

        return () => {
            socket.disconnect();
            // socket && socket.emit('disconnect');
        };

    }, []);

    return (
        <div className="main-layout">
            <Sidebar/>
            <main className="big-container">
                <Header />
                <Switch>
                    <Route path="/home" component={Dashboard} />
                    <Route path="/updates" component={Updates} />
                    <Route exact path="/companies" component={Companies} />
                    <Route path="/companies/add-enterprise-client" component={EnterpriseClient} />
                    <Route path="/campaigns/:companyId" component={Campaigns} />
                    <Route path="/messages/:companyId" component={MessagesData} />
                    <Route path="/surveys" component={Surveys} />
                    <Redirect to="/home" />
                </Switch>
            </main>
        </div>
    );
};

export default MainLayout;