import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { loginUser } from 'store/user/actions';
import { userDataSelector } from '../../store/user/selectors';

const Login = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(userDataSelector, isEqual);
  const [loginProps, setLoginProps] = useState({
    email: '',
    password: ''
    // email: 'glengarry@glengarry.ai',
    // password: 'Blackmargroup!.' /* glengarry5252467! */
  });

  function login(e) {
    e.preventDefault();
    const {
      email,
      password
    } = loginProps;
    dispatch(loginUser(email, password));
  }

  function onChange(e, key) {
    const { value } = e.target;
    setLoginProps(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  return (
    <div className="login-container">

      <div className="container">
        <div className="row flex-column-reverse flex-md-row justify-content-center ">
          <div className="col-md-1 d-none d-md-block"/>
          <div className="col-md-4 mt-4 mt-md-0">
            <img className="login-logo-row d-block ml-auto mr-auto mb-4 mr-md-0" src="./logo.svg" alt="Logo"/>
            <Form className="login-form" autoComplete="off">
              <div className="login-form-brain" style={{ backgroundImage: 'url(/assets/images/brain.svg)' }}/>
              <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  // type="email"
                  placeholder="Email"
                  value={loginProps.email}
                  onChange={e => onChange(e, 'email')}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={loginProps.password}
                  onChange={e => onChange(e, 'password')}
                  required
                />
              </Form.Group>

              <button
                className={classNames(
                  'with-loader orange ml-auto mt-3',
                  { loading: isLoading }
                )}
                type="submit"
                onClick={login}
                disabled={isLoading}
              >
                Sign In
              </button>
            </Form>
          </div>
          <div className="col-md-4">
            <div className="login-logo-row d-none d-md-block mb-5"/>
            <div className="login-image" style={{ backgroundImage: 'url(/assets/images/login-image.svg)' }}/>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Login;