import {
  ADD_UPDATE,
  UPDATES_CALL,
  UPDATES_RESPONSE,
  UPDATES_SEEN_CALL
} from './types';

const initialState = {
  isUpdatesFetched: false,
  isUpdatesLoading: false,
  updatesData: {},
  isNewUpdate: false
};

const updatesReducer = (state = initialState, {
  type,
  payload
}) => {
  const newState = { ...state };

  switch (type) {
    case UPDATES_CALL: {
      // newState.isCompaniesFetched = false;
      newState.isUpdatesLoading = true;
      // newState.companiesData = {};

      return newState;
    }

    case UPDATES_RESPONSE: {
      newState.isUpdatesFetched = true;
      newState.isUpdatesLoading = false;

      if (payload?.updatesData) {
        newState.updatesData = { ...payload.updatesData };
      }

      return newState;
    }

    case ADD_UPDATE: {
      if (payload?.data) {
        newState.isNewUpdate = true;
        newState.updatesData = {
          count: newState.updatesData + 1,
          rows: [...newState.updatesData.rows, payload.data]
        };
        return newState;
      }
      return state;
    }

    case UPDATES_SEEN_CALL: {
      newState.isNewUpdate = false;
      newState.updatesData = {
        count: 0,
        rows: []
      };
      return newState;
    }

    default:
      return state;
  }
};

export default updatesReducer;