import {
  ADD_ALERT,
  REMOVE_ALERT
} from './types';

const initialState = {
  alertList: []
};

const appReducer = (state = initialState, {
  type,
  payload
}) => {
  const newState = { ...state };
  const newAlertList = [...newState.alertList];

  switch (type) {
    case ADD_ALERT: {
      if (payload.message) {
        const newAlert = {
          id: `_${Math.random().toString(36).substr(2, 9)}`,
          message: payload.message,
          ...payload.type && { type: payload.type },
          ...payload.duration && { duration: payload.duration }
        };

        newState.alertList = [...newAlertList, newAlert];
        return newState;
      }
      return state;
    }

    case REMOVE_ALERT: {
      if (payload.alertId) {
        newState.alertList = [...newAlertList.filter(alert => alert.id !== payload.alertId)];
        return newState;
      }
      return state;
    }

    default:
      return state;
  }
};

export default appReducer;