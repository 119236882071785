import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsCheckLg, BsXCircle, BsFillCaretRightFill, BsFillCaretDownFill } from 'react-icons/bs';
import moment from 'moment';

import { iconAccept, iconDecline, iconDrop, tagBadge } from './campaigns.module.scss';


const CollapsableRow = ({ cmpItem, acceptAction, declineAction }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const generateContacts = contactsArray => {
        let content = '';
        if (contactsArray?.length === 0) {
          return;
        // eslint-disable-next-line no-else-return
        } else if (contactsArray?.length === 1) {
          content = (
            <div className="tag-badge-wrap small-badge mb-1">
              <span>{contactsArray[0].firstName} {contactsArray[0].lastName}</span>
            </div>
          );
        } else {
          const more = contactsArray?.length > 2
              ? `, +${contactsArray?.length - 2} contacts`
              : '';
          content = (
            <div className="tag-badge-wrap small-badge mb-1">
              <span>
                {contactsArray[0].firstName} {contactsArray[0].lastName}, {contactsArray[1].firstName} {contactsArray[1].lastName} {more}
              </span>
            </div>
          );
        }
        // eslint-disable-next-line consistent-return
        return (
          <div className="tag-badge-wrap small-badge mb-1">
            <span>{content}</span>
          </div>
        );
    };

    const handleClick = () => {
        setIsCollapsed(() => !isCollapsed);
    };

    return (
        <>
            <tr key={cmpItem.id}>
                <td>
                    <button type="button" className="pt-0 pb-0 pl-1 pr-3" onClick={handleClick}>
                        {isCollapsed
                            ? <BsFillCaretDownFill size="12" className={iconDrop} />
                            : <BsFillCaretRightFill className={iconDrop} color="secondary" size="12" />}
                    </button>
                    {cmpItem.name}
                </td>
              <td>
                <div className="d-flex flex-wrap">
                    {cmpItem.audience?.tags?.map(tag => (
                        <div
                        key={tag.id}
                        className={tagBadge}
                        >
                            <span
                                className="text-light"
                                style={{
                                backgroundColor: tag.color
                                }}
                            >
                                {tag.name}
                            </span>
                        </div>
                    ))}
                    {cmpItem.audience
                        && generateContacts(cmpItem.audience.contacts)}
                </div>
              </td>
              <td>{cmpItem.messages.length}</td>
              <td>{moment(cmpItem.createdAt).format('MM/DD/YYYY')}</td>
              <td>
                <BsCheckLg size="14" className={iconAccept} onClick={acceptAction} data-id={cmpItem.id} />
                <BsXCircle size="14" className={iconDecline} onClick={declineAction} data-id={cmpItem.id} />
              </td>
            </tr>
            {isCollapsed && cmpItem.messages.map((message, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr style={{ background: 'white' }} key={`${index}-${cmpItem.id}`}>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>{message}</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                </tr>
            ))}
        </>
    );
};

// eslint-disable-next-line react/forbid-prop-types
CollapsableRow.propTypes = {
    cmpItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
    acceptAction: PropTypes.func,
    declineAction: PropTypes.func
  };

CollapsableRow.defaultProps = {
    acceptAction: () => {},
    declineAction: () => {}
  };

export default CollapsableRow;