import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

import { formLabel, formInput, formError } from './phone-input.module.scss';

export const PhoneInput = ({
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
  error,
  label
}) => (
  <FormGroup>
    <FormLabel className={formLabel} htmlFor={name}>{label}</FormLabel>
    <InputMask
      className={classNames('form-control', formInput, { 'is-invalid': isInvalid })}
      mask="+1\ (999) 999-9999"
      placeholder={placeholder}
      maskChar=""
      value={value}
      onChange={onChange}
      name={name}
      onBlur={onBlur}
    />
    {isInvalid && <FormControl.Feedback type="invalid" className={classNames('d-block', formError)}>{error}</FormControl.Feedback>}
  </FormGroup>
);

PhoneInput.defaultProps = {
  onBlur: () => {},
  isInvalid: false,
  error: ''
};

PhoneInput.propTypes = {
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};