import store from 'store';
import socketOIClient from './socket-io';

// socketInstance && socketInstance.emit('disconnect');

export default function socketInstance() {
  try {
    const userId = store.getState().userData.user.id;
    const token = store.getState().userData.activationToken;
    return socketOIClient(userId, token);
  } catch (resError) {
    console.log(resError);
    return null;
    // throw new Error(resError);
  }
}