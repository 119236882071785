import { apiFetch } from 'utils';
import { USER_LOGIN, USER_LOGIN_RESPONSE, USER_LOGOUT } from './types';
import { addAlert } from '../app/actions';

const userFields = [
  'avatar',
  'id',
  'email',
  'tempPhone',
  'tempFirstName',
  'tempLastName',
  'tempRole',
  'active',
  'verifyToken',
  'emailVerified',
  'isSuperAdmin',
  'createdAt',
  'updatedAt'
];

export const loginUser = (email, password) => async dispatch => {
  dispatch({ type: USER_LOGIN });

  let data;
  try {
    data = await apiFetch('post', '/users/sign-in', {
      email,
      password
    });

    if (data?.user?.isSuperAdmin) {
      localStorage.setItem('activationToken', data?.activationToken);
    } else {
      dispatch(addAlert('User is not a super admin', 'error'));
    }
  } catch (error) {
    if (error.message.includes('wrong email or password')) {
      dispatch(addAlert('Invalid email or password', 'error'));
    } else {
      dispatch(addAlert(error.message, 'error'));
    }
  }

  dispatch({
    type: USER_LOGIN_RESPONSE,
    ...data && { payload: data }
  });
};

export const logoutUser = () => async dispatch => {
  localStorage.removeItem('activationToken');

  dispatch({
    type: USER_LOGOUT
  });
};

export const checkAuthentication = () => async dispatch => {
  const token = localStorage.getItem('activationToken');
  if (!token) {
    return dispatch({ type: USER_LOGOUT });
  }

  try {
    const { user } = await apiFetch('get', '/users/me');

    userFields.forEach(property => {
      // eslint-disable-next-line no-prototype-builtins
      if (!user.hasOwnProperty(property)) {
        throw new Error('Invalid user data');
      }
    });

    return dispatch({
      type: USER_LOGIN_RESPONSE,
      payload: {
        user,
        activationToken: token
      }
    });
  } catch (error) {
    localStorage.removeItem('activationToken');
    return dispatch({ type: USER_LOGOUT });
  }
};